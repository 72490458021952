import React, { Component, Fragment } from 'react';
import { Button, Icon, Header, Segment, Divider, Form, Grid, Popup } from 'semantic-ui-react';
import ChoiceAnswer from '../components/ChoiceAnswer.jsx';
import MultipleChoiceAnswer from '../components/MultipleChoiceAnswer.jsx';
import { withTranslation } from 'react-i18next';

class RegisterSocialNeedsForm extends Component {

    QUESTION_ANSWERED_NO_MATCH = 'QANM';
    QUESTION_ANSWERED_MATCH = 'QAM';
    QUESTION_NOT_ANSWERED = 'QNA';

    state = {
        answeredQuestions: this.props.savedAnswers
    }

    answerQuestion = (answer) => {
        for (var i = 0; i < this.state.answeredQuestions.length; i++) {
            let answered = this.state.answeredQuestions[i];
            if (answered.questionnaireItem.uuid === answer.questionnaireItem.uuid) {
                const index = this.state.answeredQuestions.indexOf(answered);
                if (index > -1) {
                    this.state.answeredQuestions.splice(index, 1);
                }
            }
        }
        let answeredQuestions = this.state.answeredQuestions;
        answeredQuestions.push(answer);
        this.setState({ answeredQuestions: answeredQuestions });
    }

    getAnswers = (question) => {
        return question.questionnaireAnswerOptionOutputListModel.questionnaireAnswerOptions;
    }

    getTextValue = (questionId) => {
        return '';
    }

    getDateValue = (name, initialValue) => {
        if (this.state[name]) {
            return this.state[name];
        } else {
            return initialValue;
        }
    }

    getAnswerInput = (question, answers) => {
        let value = this.getTextValue(question.uuid);
        if (question.type === "multipleChoice") {
            return <MultipleChoiceAnswer answers={answers}
                question={question.uuid}
                answer={this.answerQuestion} />;
        } else if (question.type === "choice") {
            return <ChoiceAnswer answers={answers}
                question={question.uuid}
                answer={this.answerQuestion}
                registrationMode={true} />
        } else if (question.type === "text") {
            return <Form.Input name={question.uuid} onChange={this.handleChange} defaultValue={value} />;
        } else if (question.type === "textLong") {
            return <Form.TextArea name={question.uuid} onChange={this.handleChange} value={value} />;
        } else if (question.type === "date") {
            return <Form.Input type="date" name={question.uuid} onChange={this.handleChange}
                value={this.getDateValue(question.uuid, value)} />;
        } else if (question.type === "theme") {
            return <ChoiceAnswer answers={answers}
                question={question.uuid}
                answer={this.answerQuestion}
                registrationMode={true} />;
        }
    }

    doesAnswerMatchAnswers = (enableQuestionId, enableAnswerText) => {
        let returnValue = null;
        for (var i = 0; i < this.state.answeredQuestions.length; i++) {
            let answered = this.state.answeredQuestions[i];
            if (answered.questionnaireItem.uuid === enableQuestionId) {
                if (answered.text === enableAnswerText) {
                    returnValue = this.QUESTION_ANSWERED_MATCH;
                    break;
                } else {
                    returnValue = this.QUESTION_ANSWERED_NO_MATCH;
                }
            }
        }
        for (var j = 0; j < this.props.backgroundAnswers.length; j++) {
            let answered = this.props.backgroundAnswers[j];
            if (answered.questionnaireItem.uuid === enableQuestionId) {
                if (answered.text === enableAnswerText) {
                    returnValue = this.QUESTION_ANSWERED_MATCH;
                    break;
                } else {
                    returnValue = this.QUESTION_ANSWERED_NO_MATCH;
                }
            }
        }
        if (!returnValue) {
            returnValue = this.QUESTION_NOT_ANSWERED;
        }
        return returnValue;
    }

    isQuestionAnswered = (question) => {
        for (var i = 0; i < this.state.answeredQuestions.length; i++) {
            let answered = this.state.answeredQuestions[i];
            if (answered.questionnaireItem.uuid === question.uuid) {
                return answered;
            }
        }
        return;
    }

    getMessage = (question) => {
        const { t } = this.props;
        if (question.enableOperator) {
            return <Segment style={{ backgroundColor: '#f3f3f3' }}><Icon name='info circle' color='blue' />{t(question.enableOperator)}</Segment>;
        }
    }

    clearAnswers = (question) => {
        for (var i = 0; i < this.state.answeredQuestions.length; i++) {
            let answered = this.state.answeredQuestions[i];
            if (answered.questionnaireItem.uuid === question.uuid) {
                const index = this.state.answeredQuestions.indexOf(answered);
                if (index > -1) {
                    this.state.answeredQuestions.splice(index, 1);
                }
            }
        }
        for (var j = 0; j < this.props.questions.length; j++) {
            let otherQuestion = this.props.questions[j];
            if (otherQuestion.enableQuestion && (otherQuestion.enableQuestion.uuid === question.uuid) ){
                this.clearAnswers(otherQuestion);
            }
        }
        let answeredQuestions = this.state.answeredQuestions;
        this.setState({ answeredQuestions: answeredQuestions });
        return;
    }

    getQuestion = (question) => {
        const { t } = this.props;
        let answers = this.getAnswers(question);
        let showQuestion = true;
        if (question.enableQuestion) {
            showQuestion = false;
            let enableQuestionId = question.enableQuestion.uuid;
            let enableAnswerText = question.enableAnswer;
            let answerMatch = this.doesAnswerMatchAnswers(enableQuestionId, enableAnswerText);
            if (answerMatch === this.QUESTION_ANSWERED_MATCH) {
                showQuestion = true;
            } else if (answerMatch === this.QUESTION_ANSWERED_NO_MATCH) {
                showQuestion = false;
            } else if (answerMatch === this.QUESTION_NOT_ANSWERED) {
                showQuestion = false;
            }
        }
        if (showQuestion) {
            let questionAnswered = this.isQuestionAnswered(question);
            if (question.type === "theme") {
                if (questionAnswered) {  
                    if (questionAnswered.text.includes('Ja')) {
                        return <Segment style={{ backgroundColor: '#385773' }}>
                            <Grid columns={2}>
                                <Grid.Column width={14}>
                                    <Header inverted>
                                        <Icon name='check circle' />
                                        <Header.Content>{t(questionAnswered.comment)}</Header.Content></Header>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Popup
                                        trigger={<Button size='mini' color='white' icon='undo' circular floated='right'
                                            onClick={this.clearAnswers.bind(this, question)} />}
                                        content={t("Answer The Question Again")}
                                        size='mini'
                                        position='right center'
                                        mouseEnterDelay={500}
                                    /></Grid.Column>
                            </Grid>
                        </Segment>
                    } else {
                        return <Fragment>
                            <Segment secondary>
                                <Grid columns={2}>
                                    <Grid.Column width={14}>
                                        <Header>
                                            <Icon name='times circle' color='grey' />
                                            <Header.Content>{t(questionAnswered.comment)}</Header.Content></Header>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                        <Popup
                                            trigger={<Button size='mini' color='white' icon='undo' circular floated='right'
                                                onClick={this.clearAnswers.bind(this, question)} />}
                                            content={t("Answer The Question Again")}
                                            size='mini'
                                            position='right center'
                                            mouseEnterDelay={500}
                                        /></Grid.Column>
                                </Grid>
                            </Segment>
                            <Divider hidden />
                        </Fragment>
                    }
                } else {
                    return <Fragment>
                        <Segment style={{ backgroundColor: '#385773' }}>
                            <Header content={t(question.text)} inverted />
                        </Segment>
                        {this.getMessage(question)}
                        <Segment basic>{this.getAnswerInput(question, answers)}
                        </Segment>
                    </Fragment>
                }
            } else {
                if (questionAnswered) {
                    return <Segment basic compact>
                        <Icon name='chevron right' color='blue' />{t(questionAnswered.comment)}</Segment>;
                } else {
                    return <Segment basic>
                        <Segment style={{ backgroundColor: '#f3f3f3' }}>
                            <Header size='small' content={t(question.text)} />
                        </Segment>
                        {this.getMessage(question)}
                        {this.getAnswerInput(question, answers)}
                    </Segment>;
                }
            }
        }
    }

    toNextStage = () => {
        this.props.answers(this.state.answeredQuestions);
        this.props.nextStage();
    }

    toPreviousStage = () => {
        this.props.answers(this.state.answeredQuestions);
        console.log(JSON.stringify(this.state.answeredQuestions));
        this.props.previousStage();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    sortQuestions = (questions) => {
        return [].concat(questions).sort((a, b) => a.orderNumber > b.orderNumber ? 1 : -1);
    }

    render() {
        console.log("Questions: " + JSON.stringify(this.props.questions));
        const { t } = this.props;
        let questions = this.sortQuestions(this.props.questions);
        return <Fragment>
            <Header as='h1'>{t("Needs Title")}</Header>
            <Segment raised>
                {questions.slice(0, questions.length).map((item, index) =>
                    <Form size='large' key={index}>{this.getQuestion(item)}</Form>)}
            </Segment>
            <Divider hidden />           
            <Button onClick={this.toPreviousStage} icon labelPosition='left' color='blue' floated='left'>
                {t("Back")}
                <Icon name='left arrow' />
            </Button>
            <Button onClick={this.toNextStage} icon labelPosition='right' color='blue' floated='right'>
                {t("Next")}
                <Icon name='right arrow' />
            </Button>
        </Fragment>;
    }

}

export default withTranslation() (RegisterSocialNeedsForm);
