import React, { Fragment } from 'react';
import { Segment, Grid, Icon, Loader, List, Header, Popup, Divider, Image } from 'semantic-ui-react';
import i18n from "../i18n";
import socialekaart from '../images/Desocialekaart.svg';
import socialbxlnl from '../images/sociaalbxlnl.png';
import socialbxlfr from '../images/sociaalbxlfr.png';
import Configuration from '../config/Configuration.js';

class ServiceUtils {

    static getPractitionerName = (service) => {
        if (service.practitioner) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='user' color='blue' />
                </Grid.Column>
                <Grid.Column width={14}>
                    {service.practitioner.name}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    static getReferralEmails = (healthcareServiceEmails, props) => {
        const { t } = props;
        return <List>
            {healthcareServiceEmails.slice(0, healthcareServiceEmails.length).map((healthcareServiceEmail, index) =>
                <List.Item key={index}><List.Icon name='mail' color='blue' key={index} />{t(healthcareServiceEmail)}</List.Item>
            )}
        </List>;
    }

    static getReferralMethod = (referralMethod, props) => {
        const { t } = props;
        let directReferral = false;
        if (referralMethod === "email") {
            directReferral = true;
        }
        if (directReferral) {
            return <p><Icon color='green' name='check' />{t("Direct Referrals Through Zipster")}</p>;
        } else {
            return <p><Icon color='red' name='times' />{t("No Direct Referrals Through Zipster")}</p>;
        }
    }

    static getReferralMethodIcon = (referralMethod) => {
        let directReferral = false;
        if (referralMethod === "email") {
            directReferral = true;
        }
        if (directReferral) {
            return <Icon color='green' name='check' />;
        } else {
            return <Icon color='red' name='times' />;
        }
    }

    static getCodableConcepts = (service, codableConcepts, compactView, props) => {
        const { t } = props;
        let codableConceptTexts = [];
        if (codableConcepts) {
            for (var i = 0; i < service.codableConcepts.length; i++) {
                let serviceCodableConcept = service.codableConcepts[i];
                for (var j = 0; j < codableConcepts.length; j++) {
                    let codableConcept = codableConcepts[j];
                    if (codableConcept.uuid === serviceCodableConcept.uuid) {
                        codableConceptTexts.push(codableConcept.text);
                    }
                }
            }
            if (compactView) {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) =>
                        <List.Item key={index}><List.Icon name='check circle outline' color='blue' key={index} />{t(codableConceptText)}</List.Item>
                    )}
                </List>;
            } else {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) =>
                        <List.Item key={index}><List.Icon name='angle double right' color='blue' key={index} />{t(codableConceptText)}</List.Item>
                    )}
                </List>;
            }
        } else {
            return <Loader active inline='centered' />;
        }
    }

    static getHCSDescription = (comment) => {
        let description = comment;
        try {
            let commentObj = JSON.parse(comment);
            description = commentObj.description;
            description = description.split('\n').map(str => <p>{str}</p>);
        } catch (e) {
        }
        return description;
    }

    static getHCSLanguages = (comment, props) => {
        const { t } = props;
        let languages = "";
        try {
            let commentObj = JSON.parse(comment);
            let supportedLanguages = commentObj.supportedLanguages;
            languages = (
                <ul>
                    {supportedLanguages.map((language, index) => (
                        <li key={index}>
                            {t(language.language)}
                        </li>
                    ))}
                </ul>
            );;
        } catch (e) {
        }
        return languages;
    }

    static getSocialeKaartLabel = (healthcareService, showHealthcareServiceModal, props) => {
        const { t } = props;
        if (healthcareService.socialeKaartReference) {

            let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
            if (bxlSocialId) {
                if (showHealthcareServiceModal) {
                    return <Popup content={t("Source Social Brussels")} size='mini' trigger={<Icon floated size='large' color='yellow' name='database' />} />;
                } else {
                    return <Popup content={t("Source Social Brussels")} size='mini' trigger={<Icon floated size='mini' color='yellow' name='database' />} />;
                }
            } else {
                if (showHealthcareServiceModal) {
                    return <Popup content={t("Source Sociale Kaart")} size='mini' trigger={<Icon floated size='large' color='orange' name='database' />} />;
                } else {
                    return <Popup content={t("Source Sociale Kaart")} size='mini' trigger={<Icon floated size='mini' color='orange' name='database' />} />;
                }
            }
        } else {
            if (showHealthcareServiceModal) {
                return <Popup content={t("Source Zipster")} size='mini' trigger={<Icon floated size='large' color='blue' name='database' />} />;
            } else {
                return <Popup content={t("Source Zipster")} size='mini' trigger={<Icon floated size='mini' name='database' />} />;
            }
        }
    }

    static getPhoneFromService = (service) => {
        if (service.phone) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='phone' color='blue' />
                </Grid.Column>
                <Grid.Column>
                    {service.phone}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    static getEmailFromService = (service) => {
        if (service.healthcareServiceEmails && service.healthcareServiceEmails.length > 0 && service.healthcareServiceEmails[0]) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='mail' color='blue' />
                </Grid.Column>
                <Grid.Column>
                    {service.healthcareServiceEmails[0]}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    static getContactInfoFromService = (service, props) => {
        const { t } = props;
        return <Fragment>
            <Header size='small'>{t("Contact Details")}</Header>
            <Divider />
            <Grid columns={2} relaxed>
                {ServiceUtils.getPhoneFromService(service)}
                {ServiceUtils.getEmailFromService(service)}
            </Grid>
        </Fragment>
    }

    static getParentOrganizationFromService = (healthcareService, props) => {
        const { t } = props;
        return <Fragment>
            <Header size='small'>{t("Organisation")}</Header>
            <Divider />
            {healthcareService.provider.name}
        </Fragment>;
    }

    static getSupportNeeds = (healthcareService, codableConcepts, compactView, props) => {
        const { t } = props;
        return <Segment secondary raised>
            <Header size='small'>{t("Support Needs")}</Header>
            <Divider />
            {ServiceUtils.getCodableConcepts(healthcareService, codableConcepts, compactView, props)}
        </Segment>;
    }

    static showZipsterService = (healthcareService, codableConcepts, compactView, props) => {
        const { t } = props;
        return <Segment basic>
            <Grid columns={2} stackable>
                <Grid.Column>
                    <Segment basic>
                        <Header size='small'>{t("Service Description")}</Header>
                        <Divider />
                        <p>{ServiceUtils.getHCSDescription(healthcareService.comment)}</p>
                        <Header size='small'>{t("Characteristics")}</Header>
                        <Divider />
                        <p>{ServiceUtils.getReferralMethod(healthcareService.referralMethod, props)}</p>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <Segment basic>
                        {ServiceUtils.getSupportNeeds(healthcareService, codableConcepts, compactView, props)}
                        {ServiceUtils.getParentOrganizationFromService(healthcareService, props)}
                        {ServiceUtils.getContactInfoFromService(healthcareService, props)}
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>;
    }

    static getBxlSocialPhone = (serviceDetails) => {
        if (i18n.language.startsWith("fr")) {
            return serviceDetails.telFr;
        } else {
            return serviceDetails.telNl;
        }
    }

    static getBxlSocialEmail = (serviceDetails) => {
        if (i18n.language.startsWith("fr")) {
            return serviceDetails.emailFr;
        } else {
            return serviceDetails.emailNl;
        }
    }

    static getBxlSocialWebsite = (serviceDetails) => {
        if (i18n.language.startsWith("fr")) {
            return serviceDetails.websiteOfficialFr;
        } else {
            return serviceDetails.websiteOfficialNl;
        }
    }

    static getEmailFromServiceDetails = (serviceDetails) => {
        if (serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (serviceDetails.contactInfo.emails) {
                if (serviceDetails.contactInfo.emails[0]) {
                    return <Grid.Row>
                        <Grid.Column width={2}>
                            <Icon name='mail' color='blue' />
                        </Grid.Column>
                        <Grid.Column width={14}>
                            {serviceDetails.contactInfo.emails[0].value}
                        </Grid.Column>
                    </Grid.Row>;
                }
            }
        } else if (serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            let email = ServiceUtils.getBxlSocialEmail(serviceDetails);
            if (email[0]) {
                return <Grid.Row>
                    <Grid.Column width={2}>
                        <Icon name='mail' color='blue' />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        {email[0]}
                    </Grid.Column>
                </Grid.Row>;
            }
        }
    }

    static getPhoneNumberFromServiceDetails = (serviceDetails) => {
        if (serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (serviceDetails.contactInfo.phones) {
                if (serviceDetails.contactInfo.phones[0]) {
                    return <Grid.Row>
                        <Grid.Column width={2}>
                            <Icon name='phone' color='blue' />
                        </Grid.Column>
                        <Grid.Column width={14}>
                            {serviceDetails.contactInfo.phones[0].value}
                        </Grid.Column>
                    </Grid.Row>;
                }
            }
        } else if (serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            let phone = ServiceUtils.getBxlSocialPhone(serviceDetails);
            if (phone) {
                if (phone[0]) {
                    return <Grid.Row>
                        <Grid.Column width={2}>
                            <Icon name='phone' color='blue' />
                        </Grid.Column>
                        <Grid.Column width={14}>
                            {phone[0]}
                        </Grid.Column>
                    </Grid.Row>;
                }
            }
        }
    }

    static getWebsiteFromServiceDetails = (serviceDetails) => {
        if (serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (serviceDetails.contactInfo.websites) {
                if (serviceDetails.contactInfo.websites[0]) {
                    return <Grid.Row>
                        <Grid.Column width={2}>
                            <Icon name='globe' color='blue' />
                        </Grid.Column>
                        <Grid.Column width={14}>
                            <a href={serviceDetails.contactInfo.websites[0].value} target="_blank" rel="noopener noreferrer">{serviceDetails.contactInfo.websites[0].value}</a>
                        </Grid.Column>
                    </Grid.Row>
                }
            }
        } else if (serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            let website = ServiceUtils.getBxlSocialWebsite(serviceDetails);
            if (website) {
                if (website[0]) {
                    return <Grid.Row>
                        <Grid.Column width={2}>
                            <Icon name='globe' color='blue' />
                        </Grid.Column>
                        <Grid.Column width={14}>
                            <a href={website[0]} target="_blank" rel="noopener noreferrer">{serviceDetails.websiteOfficialFr[0]}</a>
                        </Grid.Column>
                    </Grid.Row>
                }
            }
        }
    }

    static getStreet = (serviceDetails) => {
        if (serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (serviceDetails.addresses) {
                if (serviceDetails.addresses[0]) {
                    if (serviceDetails.addresses[0].street && serviceDetails.addresses[0].street.name)
                        return serviceDetails.addresses[0].street.name.description;
                }
            }
        } else if (serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            if (serviceDetails.address) {
                if (i18n.language.startsWith("fr")) {
                    if (serviceDetails.address.streetFr)
                        return serviceDetails.address.streetNl;
                } else if (serviceDetails.address.streetFr)
                    return serviceDetails.address.streetNl;
            }
        }
    }

    static getHouseNumber = (serviceDetails) => {
        if (serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (serviceDetails.addresses) {
                if (serviceDetails.addresses[0]) {
                    if (serviceDetails.addresses[0].number)
                        return serviceDetails.addresses[0].number;
                }
            }
        } else if (serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            if (serviceDetails.address) {
                if (serviceDetails.address.number)
                    return serviceDetails.address.number;
            }
        }
    }

    static getPostalCode = (serviceDetails) => {
        if (serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (serviceDetails.addresses) {
                if (serviceDetails.addresses[0]) {
                    if (serviceDetails.addresses[0].municipality)
                        return serviceDetails.addresses[0].municipality.postalCode;
                }
            }
        } else if (serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            if (serviceDetails.address) {
                if (serviceDetails.address.zipCode)
                    return serviceDetails.address.zipCode;
            }
        }
    }

    static capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    static getMunicipality = (serviceDetails) => {
        if (serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (serviceDetails.addresses) {
                if (serviceDetails.addresses[0]) {
                    if (serviceDetails.addresses[0].municipality)
                        return serviceDetails.addresses[0].municipality.name.description;
                }
            }
        } else if (serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            if (serviceDetails.address) {
                if (i18n.language.startsWith("fr")) {
                    if (serviceDetails.address.municipalityFr) {
                        return ServiceUtils.capitalize(serviceDetails.address.municipalityFr);
                    }
                } else {
                    if (serviceDetails.address.municipalityNl) {
                        return ServiceUtils.capitalize(serviceDetails.address.municipalityNl);
                    }
                }
            }
        }
    }

    static getAddressFromServiceDetails = (serviceDetails) => {
        return <Grid.Row>
            <Grid.Column width={2}>
                <Icon name='location arrow' color='blue' />
            </Grid.Column>
            <Grid.Column width={14}>
                {ServiceUtils.getStreet(serviceDetails)}, {ServiceUtils.getHouseNumber(serviceDetails)} <br />
                {ServiceUtils.getPostalCode(serviceDetails)} {ServiceUtils.getMunicipality(serviceDetails)}
            </Grid.Column>
        </Grid.Row>
    }

    static getContactInfo = (service, props) => {
        const { t } = props;
        if (service.serviceDetails) {
            return <Fragment>
                <Header size='small'>{t("Contact Details")}</Header>
                <Divider />
                <Grid columns={2} relaxed>
                    {ServiceUtils.getPhoneNumberFromServiceDetails(service.serviceDetails)}
                    {ServiceUtils.getEmailFromServiceDetails(service.serviceDetails)}
                    {ServiceUtils.getWebsiteFromServiceDetails(service.serviceDetails)}
                    {ServiceUtils.getAddressFromServiceDetails(service.serviceDetails)}
                    {ServiceUtils.getPractitionerName(service)}
                </Grid>
            </Fragment>;
        }
    }

    static showExternalServiceInfo = (healthcareService, codableConcepts, compactView, props) => {
        return <Segment basic>
            <Grid columns={2} stackable>
                <Grid.Column width={8}>
                    {ServiceUtils.getGeneralInfo(healthcareService, props)}
                </Grid.Column>
                <Grid.Column width={8}>
                    <Segment basic>
                        {ServiceUtils.getSupportNeeds(healthcareService, codableConcepts, compactView, props)}
                        {ServiceUtils.getParentOrganizationFromService(healthcareService, props)}
                        {ServiceUtils.getContactInfo(healthcareService, props)}
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>;
    }

    static showExternalServiceInfoWithoutNeeds = (healthcareService, props) => {
        return <Segment basic>
            <Grid columns={2} stackable>
                <Grid.Column width={8}>
                    {ServiceUtils.getGeneralInfo(healthcareService, props)}
                </Grid.Column>
                <Grid.Column width={8}>
                    <Segment basic>
                        {ServiceUtils.getParentOrganizationFromService(healthcareService, props)}
                        {ServiceUtils.getContactInfo(healthcareService, props)}
                    </Segment>
                </Grid.Column>
            </Grid>
        </Segment>;
    }

    static getSocialeKaartButton = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
            if (bxlSocialId) {
                if (i18n.language.startsWith("fr")) {
                    return <Segment basic textAlign='center'>
                        <Image as='a'
                            href={'https://social.brussels/organisation/' + bxlSocialId}
                            target='_blank'
                            size='small' src={socialbxlfr} />
                    </Segment>;
                } else {
                    return <Segment basic textAlign='center'>
                        <Image as='a'
                            href={'https://social.brussels/organisation/' + bxlSocialId}
                            target='_blank'
                            size='small' src={socialbxlnl} />
                    </Segment>;
                }
            } else {
                return <Segment basic textAlign='center'>
                    <Image as='a'
                        href={'https://www.desocialekaart.be/fiches/' + healthcareService.socialeKaartReference}
                        target='_blank'
                        size='small' src={socialekaart} />
                </Segment>;
            }
        }
    }
    static getParentOrganizationId = (serviceDetails) => {
        if (serviceDetails.inrichtendeMacht.cbeId) {
            return <p> {serviceDetails.inrichtendeMacht.cbeId}</p>;
        }
    }

    static getParentOrganizationName = (serviceDetails) => {
        if (serviceDetails.inrichtendeMacht.companyName) {
            return <p>{serviceDetails.inrichtendeMacht.companyName}</p>;
        }
    }

    static getParentOrganization = (serviceDetails, props) => {
        const { t } = props;
        if (serviceDetails.inrichtendeMacht) {
            return <Segment basic>
                <Header size='small'>{t("Governing Body")}</Header>
                {ServiceUtils.getParentOrganizationName(serviceDetails)} -
                {ServiceUtils.getParentOrganizationId(serviceDetails)}
            </Segment>;
        }
    }

    static getBxlSocialTargetAudience = (serviceDetails) => {
        if (i18n.language.startsWith("fr")) {
            return serviceDetails.publicFr;
        } else {
            return serviceDetails.publicNl;
        }
    }

    static getTargetAudience = (healthcareService, props) => {
        const { t } = props;
        if (healthcareService.serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
            if (healthcareService.serviceDetails.targetAudience) {
                return <Fragment>
                    <Header size='small'>{t("Target Audience")}</Header>
                    <Divider />
                    <div dangerouslySetInnerHTML={{ __html: healthcareService.serviceDetails.targetAudience }}></div>
                </Fragment>
            }
        } else if (healthcareService.serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
            let targetAudience = ServiceUtils.getBxlSocialTargetAudience(healthcareService.serviceDetails);
            if (targetAudience) {
                return <Fragment>
                    <Header size='small'>{t("Target Audience")}</Header>
                    <Divider />
                    <div dangerouslySetInnerHTML={{ __html: targetAudience }}></div>
                </Fragment>
            }
        }
    }

    static getBxlSocialActivities = (serviceDetails) => {
        if (i18n.language.startsWith("fr")) {
            return serviceDetails.activitiesFr;
        } else {
            return serviceDetails.activitiesNl;
        }
    }

    static getLanguages = (healthcareService, props) => {
        const { t } = props;
        let languages = ServiceUtils.getHCSLanguages(healthcareService.comment, props);
        if (languages) {
            return <Fragment>
                <Header size='small'>{t("Languages")}</Header>
                <Divider />
                <p>{languages}</p>
            </Fragment>
        }
    }

    static getGeneralInfo = (healthcareService, props) => {
        const { t } = props;
        if (healthcareService.serviceDetails) {
            if (healthcareService.serviceDetails.serviceDetailsSource === new Configuration().SOCIALE_KAART) {
                return <Segment basic>
                    <Header size='small'>{t("Service Description")}</Header>
                    <Divider />
                    <div dangerouslySetInnerHTML={{ __html: healthcareService.serviceDetails.generalOperation }}></div>
                    {ServiceUtils.getTargetAudience(healthcareService, props)}
                    <Header size='small'>{t("Characteristics")}</Header>
                    <Divider />
                    <p>{ServiceUtils.getReferralMethod(healthcareService.referralMethod, props)}</p>
                </Segment>;
            } else if (healthcareService.serviceDetails.serviceDetailsSource === new Configuration().BXL_SOCIAL) {
                let activities = ServiceUtils.getBxlSocialActivities(healthcareService.serviceDetails);
                if (activities) {
                    return <Segment basic>
                        <Header size='small'>{t("Service Description")}</Header>
                        <Divider />
                        <div dangerouslySetInnerHTML={{ __html: activities }}></div>
                        {ServiceUtils.getTargetAudience(healthcareService, props)}
                        <Header size='small'>{t("Characteristics")}</Header>
                        <Divider />
                        <p>{ServiceUtils.getReferralMethod(healthcareService.referralMethod, props)}</p>
                        {ServiceUtils.getLanguages(healthcareService, props)}
                    </Segment>;
                }
            }
        }
    }

    static getBxlSocialReference = (reference) => {
        let bxlSocialReference = '';
        try {
            let referenceObj = JSON.parse(reference);
            bxlSocialReference = referenceObj.bxlSocial;
        } catch (e) {
        }
        return bxlSocialReference;
    }

}

export default ServiceUtils;