import React, { Component, Fragment } from 'react';
import { Segment, Grid, Header, Form, Icon, Container, Divider, Message, Label, List, Button, Image, HeaderContent, TabPane, Tab } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeaderPatch, authHeader, authHeaderSet, authProblemHeader, anonHeader } from '../helpers/auth-header.js';
import socialekaart from '../images/Desocialekaart.svg';
import { withTranslation } from 'react-i18next';
import ServiceUtils from '../utils/ServiceUtils.js';
import i18n from "../i18n";
import socialbxlnl from '../images/sociaalbxlnl.png';
import socialbxlfr from '../images/sociaalbxlfr.png';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader.js';

class Organisation extends Component {

    BXL_SOCIAL = "bxlSocial";
    SOCIALE_KAART = "socialeKaart";

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),

            practitionerCreationError: false,
            practitionerCreated: false,
            practitionerAlreadyRegisteredWithEmail: false,
            errorMsg: '',
            readOnlyMode: true,
            addMember: false,
            showMember: false,
            codableConcepts: [],

            organizationName: '',
            organizationType: '',
            organizationPhone: '',
            organizationEmail: '',
            organizationLogo: '',
            organizationComment: '',
            organizationAddressId: '',
            organizationContactId: '',
            organizationAddressLines: '',
            organizationCity: '',
            organizationPostalCode: '',
            organizationCountry: '',

            healthcareService: '',
            healthcareServiceName: '',
            healthcareServicePhone: '',
            healthcareServiceComment: '',
            healthcareServiceAddressId: '',
            healthcareServiceAddressLines: '',
            healthcareServiceCity: '',
            healthcareServicePostalCode: '',
            healthcareServiceCountry: '',
            healthcareServiceSocialeKaartReference: '',

            newMemberGivenName: '',
            newMemberFamilyName: '',
            newMemberEmail: '',
            newMemberPhone: '',
            newMemberGender: '',
            newMemberSpecialty: '',

            focusMember: '',

            organizationPractitionerRoles: [],
            assignedHealthcareService: '',
            assignedHealthcareServices: [],
            assignedHealthcareServicesFocusMember: [],
            pageSize: 40
        }
    }

    loadCodableConcepts() {
        let codableConcepts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    codableConcepts.push(concept);
                }
                this.setState({ codableConcepts: codableConcepts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    updateOrganizationAddress = () => {
        return fetch(this.config.ADDRESSES_URL + "/" + this.state.organizationAddressId, {
            method: 'PUT',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                lines: this.state.organizationAddressLines,
                city: this.state.organizationCity,
                postalCode: this.state.organizationPostalCode,
                country: this.state.organizationCountry
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({ readOnlyMode: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    updateHealthcareServiceAddress = () => {
        return fetch(this.config.ADDRESSES_URL + "/" + this.state.healthcareServiceAddressId, {
            method: 'PUT',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                lines: this.state.healthcareServiceAddressLines,
                city: this.state.healthcareServiceCity,
                postalCode: this.state.healthcareServicePostalCode,
                country: this.state.healthcareServiceCountry
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({ readOnlyMode: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    updateOrganization = () => {
        this.updateOrganizationAddress();
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.organizationId, {
            method: 'PUT',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                name: this.state.organizationName,
                email: this.state.organizationEmail,
                phone: this.state.organizationPhone,
                comment: this.state.organizationComment,
                address: this.state.organizationAddressId,
                contact: this.state.organizationContactId,
                logo: this.state.organizationLogo,
                type: this.state.organizationType
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({ readOnlyMode: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    updateHealthcareService = () => {
        this.updateHealthcareServiceAddress();
        return fetch(this.config.HEALTHCARESERVICES_URL + "/" + this.state.healthcareServiceId, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                name: this.state.healthcareServiceName,
                phone: this.state.healthcareServicePhone,
                comment: this.state.healthcareServiceComment,
                address: this.state.healthcareServiceAddressId,
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({ readOnlyMode: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    updateAssignedHealthcareService = (uuid, canManage) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "/" + uuid, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                canManage: canManage
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({ readOnlyMode: true });
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    makeUserAdministator = () => {
        return fetch(this.config.PRACTITIONERROLES_URL + "/" + this.state.focusMember.uuid, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                authorizationLevel: this.state.activeUser.authorizationLevel
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    deactivateUser = () => {
        return fetch(this.config.PRACTITIONERROLES_URL + "/" + this.state.focusMember.uuid, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                status: 'InActive'
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.retrievePractitioners();
                this.setState((prevState) => ({
                    focusMember: {
                        ...prevState.focusMember,
                        status: 'InActive',
                    }
                }));

                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    activateUser = () => {
        return fetch(this.config.PRACTITIONERROLES_URL + "/" + this.state.focusMember.uuid, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                status: 'Active'
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.retrievePractitioners(); this.setState((prevState) => ({
                    focusMember: {
                        ...prevState.focusMember,
                        status: 'Active',
                    }
                }));

                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    sendNewRegistrationLink = () => {
        return fetch(this.config.PRACTITIONERROLES_URL + "/" + this.state.focusMember.uuid, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                status: 'Initial'
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.retrievePractitioners(); this.setState((prevState) => ({
                    focusMember: {
                        ...prevState.focusMember,
                        status: 'PasswordReset',
                    }
                }));

                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    retrieveOrganizationAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.setState({
                    organizationAddressLines: address.lines,
                    organizationCity: address.city,
                    organizationPostalCode: address.postalCode,
                    organizationCountry: address.country,
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveHealthcareServiceAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.setState({
                    healthcareServiceAddressLines: address.lines,
                    healthcareServiceCity: address.city,
                    healthcareServicePostalCode: address.postalCode,
                    healthcareServiceCountry: address.country,
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.organizationId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.setState({
                    organizationName: organization.name,
                    organizationPhone: organization.phone,
                    organizationEmail: organization.email,
                    organizationComment: organization.comment,
                    organizationAddressId: organization.address.uuid,
                    organisationContactId: organization.contact,
                    organizationLogo: organization.logo,
                    organizationType: organization.type
                });
                this.retrieveOrganizationAddress(organization.address.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsBxlSocial = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsSocialeKaart = (serviceId) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetails = (reference) => {
        let bxlSocialId = ServiceUtils.getBxlSocialReference(reference);
        if (bxlSocialId) {
            let serviceDetails = this.getServiceDetailsBxlSocial(bxlSocialId);
            return serviceDetails;
        } else {
            let serviceDetails = this.getServiceDetailsSocialeKaart(reference);
            return serviceDetails;
        }
    }

    retrieveHealthcareService(serviceUuid) {
        return fetch(this.config.HEALTHCARESERVICES_URL + "/" + serviceUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(healthcareService => {
                this.setState({
                    healthcareServiceId: serviceUuid,
                    healthcareServiceName: healthcareService.name,
                    healthcareServicePhone: healthcareService.phone,
                    healthcareServiceComment: healthcareService.comment,
                    healthcareService: healthcareService,
                    healthcareServiceSocialeKaartReference: healthcareService.socialeKaartReference
                })
                if (healthcareService.address) {
                    this.setState({
                        healthcareServiceAddressId: healthcareService.address.uuid
                    })
                }
                if (healthcareService.socialeKaartReference) {
                    let currentComponent = this;
                    this.getServiceDetails(healthcareService.socialeKaartReference).then(function (serviceDetails) {
                        healthcareService.serviceDetails = serviceDetails;
                        currentComponent.setState({
                            healthcareService: healthcareService
                        });
                    })
                } else {
                    this.retrieveHealthcareServiceAddress(healthcareService.address.uuid);
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAssignedHealthcareServices = (practitionerRoleId) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "?practitionerRole=" + practitionerRoleId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(assignedHealthcareServices => {
                let services = assignedHealthcareServices._embedded.assignedHealthcareServices;
                return services;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAssignedHealthcareService = (practitionerRoleId, healthcareServiceId) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "?practitionerRole=" + practitionerRoleId + "&healthcareService=" + healthcareServiceId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(assignedHealthcareServices => {
                let services = assignedHealthcareServices._embedded.assignedHealthcareServices;
                return services;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrievePractitionerHealthcareService = () => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "?practitionerRole=" + this.state.activeUser.uuid + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(assignedHealthcareServices => {
                let healthcareService = null;
                let embedded = assignedHealthcareServices._embedded.assignedHealthcareServices;
                if (embedded.length === 1) {
                    healthcareService = embedded[0].healthcareService;
                    this.setState({
                        assignedHealthcareService: healthcareService,
                        assignedHealthcareServices: embedded
                    });
                    this.retrieveHealthcareService(healthcareService.uuid);
                } else if (embedded.length > 1) {
                    this.setState({ assignedHealthcareServices: embedded });
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveMemberHealthcareService = (memberId) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "?practitionerRole=" + memberId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(assignedHealthcareServices => {
                let embedded = assignedHealthcareServices._embedded.assignedHealthcareServices;
                this.setState({ assignedHealthcareServicesFocusMember: embedded });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    deleteMemberHealthcareService = (memberId) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "/" + memberId, {
            method: 'DELETE',
            headers: authProblemHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    partOfActiveUserServices = (services) => {
        let serviceIds = [];
        if (this.state.assignedHealthcareServices) {
            for (var i = 0; i < this.state.assignedHealthcareServices.length; i++) {
                let service = this.state.assignedHealthcareServices[i];
                serviceIds.push(service.healthcareService.uuid);
            }
            for (var j = 0; j < services.length; j++) {
                let service = services[j];
                if (serviceIds.includes(service.healthcareService.uuid)) {
                    return true;
                }
            }
        }
        return false;
    }

    retrievePractitioners = () => {
        return fetch(this.config.PRACTITIONERROLES_URL + "?organization=" + this.state.organizationId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                this.setState({ organizationPractitionerRoles: [] });
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(practitionerRoles => {
                let roles = practitionerRoles._embedded.practitionerRoles;
                let currentComponent = this;
                if (this.state.assignedHealthcareServices && this.state.assignedHealthcareServices.length > 0) {
                    for (var i = 0; i < roles.length; i++) {
                        let role = roles[i];
                        let promise = this.retrieveAssignedHealthcareServices(role.uuid);
                        if (promise) {
                            promise.then(function (assignedServices) {
                                if (currentComponent.partOfActiveUserServices(assignedServices)) {
                                    let activeRoles = currentComponent.state.organizationPractitionerRoles;
                                    activeRoles.push(role);
                                    currentComponent.setState({ organizationPractitionerRoles: activeRoles });
                                }
                            })
                        }
                    }
                } else {
                    this.setState({ organizationPractitionerRoles: roles });
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrievePractitionerRole = (practitionerId) => {
        return fetch(this.config.PRACTITIONERROLES_URL + "/" + practitionerId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    assignPractitionerToHealthcareService = (practitionerRoleId, healthcareServiceId) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                practitionerRole: practitionerRoleId,
                healthcareService: healthcareServiceId,
                canManage: true
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    assignHealthcareService = (practitionerRoleId) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                practitionerRole: practitionerRoleId,
                healthcareService: this.state.assignedHealthcareService.uuid,
                canManage: true
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    createMemberRole = (practitionerId) => {
        return fetch(this.config.PRACTITIONERROLES_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                name: this.state.newMemberGivenName + " " + this.state.newMemberFamilyName,
                organization: this.state.organizationId,
                practitioner: practitionerId,
                email: this.state.newMemberEmail,
                speciality: this.state.newMemberSpecialty,
                phone: this.state.newMemberPhone,
                userName: this.state.newMemberEmail,
                authorizationLevel: "USER",
                isObserver: this.state.activeUser.isObserver
            })
        }).then(response => {
            if (!response.ok) {
                this.setState({ practitionerCreationError: true })
            } else {
                this.setState({
                    practitionerCreated: true,
                    addMember: false,
                    newMemberGivenName: '',
                    newMemberFamilyName: '',
                    newMemberPhone: '',
                    newMemberEmail: '',
                    newMemberGender: ''
                });
                return response.json();
            }
        }).then(practitionerRole => {
            this.assignHealthcareService(practitionerRole.id);
            this.retrievePractitioners();
        }).catch(error => {
            this.handleError(error);
        });
    }

    createMember = () => {
        return fetch(this.config.PRACTITIONERS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                name: this.state.newMemberGivenName + " " + this.state.newMemberFamilyName,
                familyName: this.state.newMemberFamilyName,
                givenName: this.state.newMemberGivenName,
                phone: this.state.newMemberPhone,
                email: this.state.newMemberEmail,
                gender: this.state.newMemberGender,
                identifier: null
            })
        }).then(response => {
            if (!response.ok) {
                this.setState({ practitionerCreationError: true })
            } else {
                this.setState({
                    practitionerCreated: true,
                });
                return response.json();
            }
        }).then(practitioner => {
            this.createMemberRole(practitioner.id);
        }).catch(error => {
            this.handleError(error);
        });
    }

    verifyMemberAndCreate = () => {
        return fetch(this.config.PRACTITIONERS_URL + "?email=" + this.state.newMemberEmail, {
            method: 'GET',
            headers: authHeaderSet()
        }).then(response => {
            if (!response.ok) {
                this.setState({ practitionerCreationError: true })
            } else {
                return response.json();
            }
        }).then(practitioner => {
            let practitioners = practitioner._embedded.practitioners;
            if (practitioners.length > 0) {
                this.setState({ practitionerAlreadyRegisteredWithEmail: true })
            } else {
                this.setState({ practitionerAlreadyRegisteredWithEmail: false })
                this.createMember();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    componentDidMount() {
        if (this.state.codableConcepts.length === 0) {
            this.loadCodableConcepts();
        }
        this.setState({
            organizationId: this.state.activeUser.organization.uuid
        },
            () => {
                this.retrieveOrganization();
                let promise = this.retrievePractitionerHealthcareService();
                let currentComponent = this;
                if (promise) {
                    promise.then(function () {
                        currentComponent.retrievePractitioners();
                    })
                }
            });
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    enableEditMode = () => {
        this.setState({ readOnlyMode: false });
    }

    getOrganizationEditButton = () => {
        const { t } = this.props;
        if (this.state.activeUser.authorizationLevel === 'ADMIN' ||
            this.state.activeUser.authorizationLevel === 'ELZADMIN') {
            if (this.state.readOnlyMode) {
                return <Form.Button onClick={this.enableEditMode} icon labelPosition='right' color='blue'>
                    {t("Edit")}<Icon name='edit' />
                </Form.Button>;
            } else {
                return <Form.Button onClick={this.updateOrganization} icon labelPosition='right' color='green'>
                    {t("Save")}<Icon name='save' />
                </Form.Button>;
            }
        }
    }

    getHealthcareServiceEditButton = () => {
        const { t } = this.props;
        if (this.state.activeUser.authorizationLevel === 'ADMIN' ||
            this.state.activeUser.authorizationLevel === 'ELZADMIN') {
            if (this.state.readOnlyMode) {
                return <Form.Button onClick={this.enableEditMode} icon labelPosition='right' color='blue'>
                    {t("Edit")}<Icon name='edit' />
                </Form.Button>;
            } else {
                return <Form.Button onClick={this.updateHealthcareService} icon labelPosition='right' color='green'>
                    {t("Save")}<Icon name='save' />
                </Form.Button>;
            }
        }
    }

    addMember = () => {
        this.setState({
            addMember: true,
            practitionerCreated: false
        });
    }

    getAddMemberButton = () => {
        const { t } = this.props;
        if (!this.state.addMember && (this.state.activeUser.authorizationLevel === 'ADMIN' ||
            this.state.activeUser.authorizationLevel === 'ELZADMIN')) {
            return <Form.Button onClick={this.addMember} icon labelPosition='right' color='blue'>
                {t("Add Employee")}
                <Icon name='add' />
            </Form.Button>;
        }
    }

    getEmailInput = () => {
        const { t } = this.props;
        if (this.state.practitionerAlreadyRegisteredWithEmail) {
            return <Form.Input
                required
                fluid
                label={t("Email")}
                placeholder={t("Email")}
                name='newMemberEmail'
                value={this.state.newMemberEmail}
                onChange={this.handleChange}
                error={{ content: t("User Exists") }} />;
        } else {
            return <Form.Input
                required
                fluid
                label={t("Email")}
                placeholder={t("Email")}
                name='newMemberEmail'
                value={this.state.newMemberEmail}
                onChange={this.handleChange} />;
        }
    }

    getAddMemberSegment = () => {
        const { t } = this.props;
        if (this.state.addMember && !this.state.practitionerCreated) {
            return <Fragment>
                <Message
                    attached
                    header={t("Add Employee Header")}
                    content={t("Add Employee Description")}
                />
                <Form className='attached fluid segment'>
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            fluid
                            label={t("Given Name")}
                            placeholder={t("Given Name")}
                            name='newMemberGivenName'
                            value={this.state.newMemberGivenName}
                            onChange={this.handleChange} />
                        <Form.Input
                            required
                            fluid
                            label={t("Family Name")}
                            placeholder={t("Family Name")}
                            name='newMemberFamilyName'
                            value={this.state.newMemberFamilyName}
                            onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            fluid
                            label={t("Role")}
                            placeholder={t("Role")}
                            name='newMemberSpecialty'
                            value={this.state.newMemberSpecialty}
                            onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            fluid
                            label={t("Telephone Number")}
                            placeholder={t("Telephone Number")}
                            name='newMemberPhone'
                            value={this.state.newMemberPhone}
                            onChange={this.handleChange} />
                        {this.getEmailInput()}
                    </Form.Group>
                    <Form.Button onClick={this.verifyMemberAndCreate} icon labelPosition='right' color='blue'>
                        {t("Add")}
                        <Icon name='add' />
                    </Form.Button>
                </Form>
            </Fragment>;
        } else if (this.state.practitionerCreated) {
            return <Message success
                header={t("Employee Added")}
                content={t("Employee Added Description")}
            />;
        }
    }

    getAdminLabel = (practitionerRole) => {
        const { t } = this.props;
        if (practitionerRole.authorizationLevel === 'ADMIN' ||
            this.state.activeUser.authorizationLevel === 'ELZADMIN') {
            return <Fragment>&nbsp;<Label size='mini' horizontal color='blue'>{t("Administrator")}</Label></Fragment>;
        }
        return;
    }

    getAssignedService = () => {
        const { t } = this.props;
        if (this.state.assignedHealthcareService) {
            return <Segment color='blue' inverted>
                < Header textAlign='center' >{t("Assigned Healthcare Service", { serviceName: this.state.assignedHealthcareService.name, organisationName: this.state.organizationName })}</Header >
            </Segment >;
        }
    }

    getAssignedServicesHeader = () => {
        const { t } = this.props;
        return <Segment color='blue' inverted>
            < Header textAlign='center' >{t("Your services")} {this.state.organizationName}</Header >
        </Segment >;
    }

    getEmployeesHeader = () => {
        const { t } = this.props;
        return <Segment color='blue' inverted>
            < Header textAlign='center' >{t("Employees of")} {this.state.organizationName}</Header >
        </Segment >;
    }

    showMember = (member) => {
        this.retrieveMemberHealthcareService(member.uuid);
        this.setState({
            showMember: true,
            showSelectedService: false,
            focusMember: member
        });
    }

    getMemberEditButton = (member) => {
        if (!this.state.addMember && (this.state.activeUser.authorizationLevel === 'ADMIN' || this.state.activeUser.authorizationLevel === 'ELZADMIN')) {
            return <Button color='blue' floated='right' size='mini' circular icon onClick={this.showMember.bind(this, member)}>
                <Icon name='pencil' />
            </Button>
        }
    }

    showHealthcareService = (service) => {
        this.retrieveHealthcareService(service.uuid);
        this.setState({
            showMember: false,
            showSelectedService: true
        });
    }

    getViewHealthcareServiceButton = (member) => {
        return <Button color='orange' floated='right' size='mini' circular icon onClick={this.showHealthcareService.bind(this, member.healthcareService)}>
            <Icon name='eye' />
        </Button>
    }

    getActiveEmployees = () => {
        return <Fragment>
            {this.state.organizationPractitionerRoles.slice(0, this.state.organizationPractitionerRoles.length).map((item, index) => {
                if (item.status === "Active") {
                    return <List.Item><Label color='blue' basic horizontal>{item.speciality}</Label>{item.name}{this.getAdminLabel(item)} {this.getMemberEditButton(item)}</List.Item>
                } else return null;
            })}
        </Fragment>;
    }

    getNotYetActivatedEmployees = () => {
        return <Fragment>
            {this.state.organizationPractitionerRoles.slice(0, this.state.organizationPractitionerRoles.length).map((item, index) => {
                if (item.status === "PasswordReset") {
                    return <List.Item><Label color='orange' basic horizontal>{item.speciality}</Label>{item.name}{this.getAdminLabel(item)} {this.getMemberEditButton(item)}</List.Item>
                } else return null;
            })}
        </Fragment>;
    }

    getInactiveEmployees = () => {
        return <Fragment>
            {this.state.organizationPractitionerRoles.slice(0, this.state.organizationPractitionerRoles.length).map((item, index) => {
                if (item.status === "InActive") {
                    return <List.Item><Label basic horizontal>{item.speciality}</Label>{item.name}{this.getAdminLabel(item)} {this.getMemberEditButton(item)}</List.Item>
                } else return null;
            })}
        </Fragment>;
    }

    getEmployeesPanel = () => {
        return <Fragment>
            {this.getEmployeesHeader()}
            <Container fluid>
                <Segment>
                    <List>
                        {this.getActiveEmployees()}
                        {this.getNotYetActivatedEmployees()}
                        {this.getInactiveEmployees()}
                    </List>
                    {this.getAddMemberButton()}
                </Segment>
            </Container>
            {this.getAddMemberSegment()}
        </Fragment>;
    }

    getHealthcareServiceOverviewPanel = () => {
        const { t } = this.props;
        return <Fragment>
            {this.getAssignedServicesHeader()}
            <Container fluid>
                <Segment>
                    <List relaxed>
                        {this.state.assignedHealthcareServices.slice(0, this.state.assignedHealthcareServices.length).map((item, index) =>
                            <List.Item>{item.healthcareService.name}
                                {this.getViewHealthcareServiceButton(item)}</List.Item>)}
                    </List>
                </Segment>
                <Message icon><Icon name='info circle' />{t("Access Services")}</Message>
            </Container>
        </Fragment>;
    }

    getLeftPanel = () => {
        if (this.state.activeUser.isObserver) {
            const { t } = this.props;
            let panes = [
                { menuItem: t("Employees"), render: () => <TabPane>{this.getEmployeesPanel()}</TabPane> },
                { menuItem: t("Services Organisation"), render: () => <TabPane>{this.getHealthcareServicesPanel()}</TabPane> }
            ]
            return <Tab panes={panes} />;
        } else {
            return this.getEmployeesPanel();
        }
    }

    getOrganizationPanel = () => {
        const { t } = this.props;
        return <Segment>
            <Header size='large' textAlign='center'>{this.state.organizationName}</Header>
            <Divider />
            <Segment basic>
                <Form>
                    <Form.Input
                        required
                        fluid
                        readOnly={this.state.readOnlyMode}
                        label={t("Name Organisation")}
                        placeholder={t("Name Organisation")}
                        name='organizationName'
                        value={this.state.organizationName}
                        onChange={this.handleChange} />
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            fluid
                            readOnly={this.state.readOnlyMode}
                            label={t("Telephone Number")}
                            placeholder={t("Telephone Number")}
                            name='organizationPhone'
                            value={this.state.organizationPhone}
                            onChange={this.handleChange} />
                        <Form.Input
                            fluid
                            readOnly={this.state.readOnlyMode}
                            label={t("Email")}
                            placeholder={t("Email")}
                            name='organizationEmail'
                            value={this.state.organizationEmail}
                            onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Input
                        required
                        fluid
                        readOnly={this.state.readOnlyMode}
                        label={t("Street and house number")}
                        placeholder={t("Street and house number")}
                        name='organizationAddressLines'
                        value={this.state.organizationAddressLines}
                        onChange={this.handleChange} />
                    <Form.Group widths='equal'>
                        <Form.Input
                            required
                            fluid
                            readOnly={this.state.readOnlyMode}
                            label={t("City")}
                            placeholder={t("City")}
                            name='organizationCity'
                            value={this.state.organizationCity}
                            onChange={this.handleChange} />
                        <Form.Input
                            fluid
                            readOnly={this.state.readOnlyMode}
                            label={t("Postal Code")}
                            placeholder={t("Postal code")}
                            name='organizationPostalCode'
                            value={this.state.organizationPostalCode}
                            onChange={this.handleChange} />
                    </Form.Group>
                    <Form.TextArea
                        readOnly={this.state.readOnlyMode}
                        rows={4}
                        label={t("About the organisation")}
                        placeholder={t("Description of the organisation")}
                        name='organizationComment'
                        value={this.state.organizationComment}
                        onChange={this.handleChange} />
                    {this.getOrganizationEditButton()}
                </Form>
            </Segment>
        </Segment>;
    }

    /// SOCIALE KAART VIEW ////

    getReferralMethod = (referralMethod) => {
        const { t } = this.props;
        let directReferral = false;
        if (referralMethod === "email") {
            directReferral = true;
        }
        if (directReferral) {
            return <p><Icon color='green' name='check' />{t("Direct Referrals Through Zipster")}</p>;
        } else {
            return <p><Icon color='red' name='times' />{t("No Direct Referrals Through Zipster")}</p>;
        }
    }

    getParentOrganizationFromService = (healthcareService) => {
        const { t } = this.props;
        return <Fragment>
            <Header size='small'>{t("Organisation")}</Header>
            <Divider />
            {healthcareService.provider.name}
        </Fragment>;
    }

    static getSocialeKaartButton = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
            if (bxlSocialId) {
                if (i18n.language.startsWith("fr")) {
                    return <Segment basic textAlign='center'>
                        <Image as='a'
                            href={'https://social.brussels/organisation/' + bxlSocialId}
                            target='_blank'
                            size='small' src={socialbxlfr} />
                    </Segment>;
                } else {
                    return <Segment basic textAlign='center'>
                        <Image as='a'
                            href={'https://social.brussels/organisation/' + bxlSocialId}
                            target='_blank'
                            size='small' src={socialbxlnl} />
                    </Segment>;
                }
            } else {
                return <Segment basic textAlign='center'>
                    <Image as='a'
                        href={'https://www.desocialekaart.be/fiches/' + healthcareService.socialeKaartReference}
                        target='_blank'
                        size='small' src={socialekaart} />
                </Segment>;
            }
        }
    }

    getPractitionerName = (service) => {
        if (service.practitioner) {
            return <Grid.Row>
                <Grid.Column width={2}>
                    <Icon name='user' color='blue' />
                </Grid.Column>
                <Grid.Column width={14}>
                    {service.practitioner.name}
                </Grid.Column>
            </Grid.Row>;
        }
    }

    getSelectedHealthcareServicePanel = () => {
        const { t } = this.props;
        if (this.state.healthcareServiceSocialeKaartReference) {
            let healthcareService = this.state.healthcareService;
            if (healthcareService.serviceDetails && healthcareService.serviceDetails.serviceDetailsSource === this.SOCIALE_KAART) {
                return <Segment>
                    <Header size='large' textAlign='center'>{this.state.healthcareServiceName}</Header>
                    <Divider />
                    {ServiceUtils.showExternalServiceInfoWithoutNeeds(this.state.healthcareService, this.props)}
                    <Segment basic>
                        <Segment raised><Header>{t("Sociale Kaart Unique Source")}</Header>
                            <Grid columns={2}>
                                <Grid.Column width={10}>
                                    <p>{t("Sociale Kaart Unique Source Description")}</p>
                                    <p>{t("Sociale Kaart Adjustments")} <a target='_blank' rel="noopener noreferrer" href={'https://www.desocialekaart.be/fiches/' + this.state.healthcareService.socialeKaartReference.socialekaart}>Sociale Kaart</a>.</p>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    {ServiceUtils.getSocialeKaartButton(this.state.healthcareService)}
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    </Segment>
                </Segment>;
            } else if (healthcareService.serviceDetails && healthcareService.serviceDetails.serviceDetailsSource === this.BXL_SOCIAL) {
                let bxlSocialRef = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
                return <Segment>
                    <Header size='large' textAlign='center'>{this.state.healthcareServiceName}</Header>
                    <Divider />
                    {ServiceUtils.showExternalServiceInfoWithoutNeeds(this.state.healthcareService, this.props)}
                    <Segment basic>
                        <Segment raised><Header>{t("Brussels Social Unique Source")}</Header>
                            <Grid columns={2}>
                                <Grid.Column width={10}>
                                    <p>{t("Brussels Social Unique Source Description")}</p>
                                    <p>{t("Brussels Social Adjustments")} <a target='_blank' rel="noopener noreferrer" href={'https://social.brussels/organisation/' + bxlSocialRef}>Sociale Kaart</a>.</p>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    {ServiceUtils.getSocialeKaartButton(this.state.healthcareService)}
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    </Segment>
                </Segment>;
            }
        } else {
            return <Segment>
                <Header size='large' textAlign='center'>{this.state.healthcareServiceName}</Header>
                <Divider />
                <Segment basic>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Input
                                required
                                fluid
                                readOnly={this.state.readOnlyMode}
                                label={t("Name")}
                                placeholder={t("Name service")}
                                name='healthcareServiceName'
                                value={this.state.healthcareServiceName}
                                onChange={this.handleChange} />
                            <Form.Input
                                required
                                fluid
                                readOnly={this.state.readOnlyMode}
                                label={t("Telephone Number")}
                                placeholder={t("Telephone Number")}
                                name='healthcareServicePhone'
                                value={this.state.healthcareServicePhone}
                                onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Input
                            required
                            fluid
                            readOnly={this.state.readOnlyMode}
                            label={t("Street and house number")}
                            placeholder={t("Street and house number")}
                            name='healthcareServiceAddressLines'
                            value={this.state.healthcareServiceAddressLines}
                            onChange={this.handleChange} />
                        <Form.Group widths='equal'>
                            <Form.Input
                                required
                                fluid
                                readOnly={this.state.readOnlyMode}
                                label={t("City")}
                                placeholder={t("City")}
                                name='healthcareServiceCity'
                                value={this.state.healthcareServiceCity}
                                onChange={this.handleChange} />
                            <Form.Input
                                fluid
                                readOnly={this.state.readOnlyMode}
                                label={t("Postal Code")}
                                placeholder={t("Postal Code")}
                                name='healthcareServicePostalCode'
                                value={this.state.healthcareServicePostalCode}
                                onChange={this.handleChange} />
                        </Form.Group>
                        <Form.TextArea
                            readOnly={this.state.readOnlyMode}
                            rows={4}
                            label={t("About the organisation")}
                            placeholder={t("Description of the organisation")}
                            name='healthcareServiceComment'
                            value={this.state.healthcareServiceComment}
                            onChange={this.handleChange} />
                        {this.getHealthcareServiceEditButton()}
                    </Form>
                </Segment>
            </Segment>;
        }
    }

    getHealthcareServicesPanel = () => {
        return this.getHealthcareServiceOverviewPanel();
    }

    getServicesWithoutFocusMemberAccess = () => {
        let services = [];
        for (var i = 0; i < this.state.assignedHealthcareServices.length; i++) {
            let match = false;
            for (var j = 0; j < this.state.assignedHealthcareServicesFocusMember.length; j++) {
                if (this.state.assignedHealthcareServices[i].healthcareService.uuid === this.state.assignedHealthcareServicesFocusMember[j].healthcareService.uuid
                    && this.state.assignedHealthcareServicesFocusMember[j].canManage) {
                    match = true;
                }
            }
            if (!match) {
                services.push(this.state.assignedHealthcareServices[i]);
            }
        }
        return services;
    }

    showOtherServices = () => {
        const { t } = this.props;
        let otherServices = this.getServicesWithoutFocusMemberAccess();
        if (otherServices && otherServices.length > 0) {
            return <Fragment>
                <Grid columns={16}>
                    <Grid.Column width={6}>
                        <Header size='small'>{t("Has No Access To")}</Header>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <List size='large' divided>
                            {otherServices.slice(0, otherServices.length).map((service, index) =>
                                <List.Item>{service.healthcareService.name}<Button color='blue' floated='right' size='mini' circular icon onClick={this.provideAccess.bind(this, service)}>
                                    <Icon name='user plus' />
                                </Button></List.Item>)}
                        </List>
                    </Grid.Column>
                </Grid>
            </Fragment>
        }
    }

    revokeAccess = (assignedHealthcareService) => {
        //this.deleteMemberHealthcareService(assignedHealthcareService.uuid);
        let currentComponent = this;
        let promise = this.updateAssignedHealthcareService(assignedHealthcareService.uuid, false);
        promise.then(function (response) {
            currentComponent.showMember(currentComponent.state.focusMember);
        })
    }

    provideAccess = (assignedHealthcareService) => {
        let promise = this.retrieveAssignedHealthcareService(this.state.focusMember.uuid, assignedHealthcareService.healthcareService.uuid);
        let currentComponent = this;
        if (promise) {
            promise.then(function (assignedServices) {
                if (assignedServices && assignedServices.length > 0) {
                    let targetService = assignedServices[0];
                    let secondPromise = currentComponent.updateAssignedHealthcareService(targetService.uuid, true);
                    secondPromise.then(function (response) {
                        currentComponent.showMember(currentComponent.state.focusMember);
                    })
                } else {
                    let secondPromise = currentComponent.assignPractitionerToHealthcareService(currentComponent.state.focusMember.uuid, assignedHealthcareService.healthcareService.uuid);
                    secondPromise.then(function (response) {
                        currentComponent.showMember(currentComponent.state.focusMember);
                    })
                }
            })
        }
    }

    filterServices = (services) => {
        let filteredServices = [];
        for (var i = 0; i < services.length; i++) {
            let service = services[i];
            if (service.canManage) {
                filteredServices.push(service);
            }
        }
        return filteredServices;
    }

    showActiveServices = (services) => {
        const { t } = this.props;
        if (services && services.length > 0) {
            return <Grid columns={16}>
                <Grid.Column width={6}>
                    <Header size='small'>{t("Has Access To")}</Header>
                </Grid.Column>
                <Grid.Column width={10}>
                    <List size='large' divided>
                        {services.slice(0, services.length).map((service, index) =>
                            <List.Item>{service.healthcareService.name}<Button color='orange' floated='right' size='mini' circular icon onClick={this.revokeAccess.bind(this, service)}>
                                <Icon name='user cancel' />
                            </Button></List.Item>)}
                    </List>
                </Grid.Column>
            </Grid>;
        }
    }

    showMemberServices = () => {
        const { t } = this.props;
        let filteredServices = this.filterServices(this.state.assignedHealthcareServicesFocusMember);
        if (this.state.assignedHealthcareServicesFocusMember && this.state.assignedHealthcareServicesFocusMember.length > 0) {
            return <Fragment>
                <Header attached='top' as='h3' content={t("Services Organisation")} />
                <Segment attached>
                    {this.showActiveServices(filteredServices)}
                    {this.showOtherServices()}
                </Segment>
            </Fragment>
        }
    }

    enableUserEditMode = () => {
        this.setState({ readOnlyMode: false });
    }

    getAdminRightsButton = () => {
        const { t } = this.props;
        if (this.state.focusMember.authorizationLevel === 'USER' && this.state.focusMember.status !== 'InActive') {
            return <Button size='small' color='blue' onClick={this.makeUserAdministator}>{t("Give Administrator Rights")}</Button>;
        }
    }

    getNewRegistrationButton = () => {
        const { t } = this.props;
        if (this.state.focusMember.status === 'PasswordReset') {
            return <Button size='small' color='blue' onClick={this.sendNewRegistrationLink}>{t("Send New Registration Link")}</Button>;
        } else if (this.state.focusMember.status === 'Active') {
            return <Button size='small' color='blue' onClick={this.sendNewRegistrationLink}>{t("Reset Password")}</Button>;
        }
    }

    getAccountActivationButton = () => {
        const { t } = this.props;
        if (this.state.focusMember.status !== 'InActive') {
            return <Button size='small' onClick={this.deactivateUser}>{t("Deactivate Account")}</Button>;
        } else {
            return <Button size='small' color='blue' onClick={this.activateUser}>{t("Activate Account")}</Button>;
        }
    }

    getUserAccountStatus = () => {
        const { t } = this.props;
        if (this.state.focusMember.status === 'Active') {
            return <Header as='h3'>
                <Icon name='check circle' color='blue' size='small' />
                <HeaderContent>{t("Account Activated")}</HeaderContent></Header>;
        } else if (this.state.focusMember.status === 'PasswordReset') {
            return <Header as='h3'>
                <Icon name='hourglass half' color='orange' size='small' />
                <HeaderContent>{t("Account Not Yet Activated")}</HeaderContent></Header>;
        } else if (this.state.focusMember.status === 'InActive') {
            return <Header as='h3'>
                <Icon name='user cancel' size='small' color='grey' />
                <HeaderContent>{t("Account Deactivated")}</HeaderContent></Header>;
        }
    }

    getMemberPanel = () => {
        const { t } = this.props;
        return <Segment>
            <Header size='large' textAlign='center'>{t("Manage User")}</Header>
            <Divider />
            <Segment basic>
                <Grid columns={16} textAlign='left'>
                    <Grid.Column width={6}>
                        <Header>
                            <HeaderContent>{this.state.focusMember.name}
                                <HeaderSubHeader>
                                    {this.state.focusMember.email}</HeaderSubHeader></HeaderContent>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        {this.getUserAccountStatus()}
                    </Grid.Column>
                </Grid>
                <Divider />
                <Segment basic>
                    <Grid columns={3} textAlign='center'>
                        <Grid.Column>
                            {this.getNewRegistrationButton()}
                        </Grid.Column>
                        <Grid.Column>
                            {this.getAdminRightsButton()}
                        </Grid.Column>
                        <Grid.Column>
                            {this.getAccountActivationButton()}
                        </Grid.Column>
                    </Grid>
                </Segment>
                {this.showMemberServices()}
            </Segment>
        </Segment>;
    }

    getReferralEmails = (healthcareService) => {
        const { t } = this.props;
        if (healthcareService.referralMethod === "email") {
            return <Fragment>
                <Header size='small'>{t("Emails Referrals")}</Header>
                <Divider />
                {ServiceUtils.getReferralEmails(healthcareService.healthcareServiceEmails, this.props)}
            </Fragment>
        }
    }

    getServiceSettingsPanel = () => {
        const { t } = this.props;
        return <Segment>
            <Header size='large' textAlign='center'>{this.state.healthcareServiceName}</Header>
            <Divider />
            <Segment basic>
                <Header size='small'>{t("Support Needs")}</Header>
                <Divider />
                {ServiceUtils.getCodableConcepts(this.state.healthcareService, this.state.codableConcepts, false, this.props)}
                <Header size='small'>{t("Characteristics")}</Header>
                <Divider />
                {ServiceUtils.getReferralMethod(this.state.healthcareService.referralMethod, this.props)}
                {this.getReferralEmails(this.state.healthcareService)}
            </Segment>
        </Segment>;
    }

    getSelectedHealthcareServiceTabs = () => {
        const { t } = this.props;
        let panes = [
            { menuItem: t("Description of the service"), render: () => <TabPane>{this.getSelectedHealthcareServicePanel()}</TabPane> },
            { menuItem: t("Settings"), render: () => <TabPane>{this.getServiceSettingsPanel()}</TabPane> }
        ]
        return <Tab panes={panes} />;
    }

    getMidPanel = () => {
        if (this.state.showMember) {
            return this.getMemberPanel();
        } if (this.state.showSelectedService) {
            return this.getSelectedHealthcareServiceTabs();
        } else {
            return this.getOrganizationPanel();
        }
    }

    render() {
        return <Grid columns={16} padded='horizontally' stackable>
            <Grid.Column width={6}>
                {this.getLeftPanel()}
            </Grid.Column>
            <Grid.Column width={10}>
                {this.getMidPanel()}
            </Grid.Column>
        </Grid>;
    }
}

export default withTranslation()(Organisation);
