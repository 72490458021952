import React, { Component } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Icon, Header, Segment, Button, Divider, Grid, Container } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { withTranslation } from 'react-i18next';
import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});

const { Media, MediaContextProvider } = AppMedia;

class ChooseReferralType extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
        }
    }

    render() {
        const { t } = this.props;
        let patientId = this.props.location.state.patientId;
        let name = this.props.location.state.patientName;
        let givenName = this.props.location.state.patientGivenName;
        let postalCode = this.props.location.state.patientPostalCode;
        let email = this.props.location.state.patientEmail;
        let locationBasedConcepts = this.props.location.state.locationBasedConcepts;
        return <MediaContextProvider><Segment>
            <Divider hidden />
            <Header as='h1' textAlign='center'>{name}<Header.Subheader>
                {t("Referral Options")}
            </Header.Subheader></Header>
            <Divider hidden />
            <Container as={Media} lessThan="computer" fluid>
                <Grid columns={2} stretched stackable>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Header as='h3' icon>
                                <Icon name='map signs' color='blue' size='small' />
                                {t("Needs Known")}
                                <Header.Subheader>
                                    {t("Needs Known Description", { givenName: givenName })}
                                </Header.Subheader>
                            </Header>
                            <Button
                                color='blue'
                                as={Link}
                                to="/needsbasedreferral"
                                state={{
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode,
                                    email: email,
                                    locationBasedConcepts: locationBasedConcepts,
                                }}>
                                {t("Show Offer")}
                            </Button>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Header as='h3' icon>
                                <Icon name='mail forward' color='blue' size='small' />
                                {t("Direct Referral")}
                                <Header.Subheader>
                                    {t("Direct Referral Description", { givenName: givenName })}
                                </Header.Subheader>
                            </Header>
                            <Button
                                color='blue'
                                as={Link}
                                to="/directreferral"
                                state={{
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode
                                }}
                            >
                                {t("Start Referral")}
                            </Button>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
            <Container as={Media} greaterThanOrEqual="computer" fluid>
                <Grid columns='equal' stretched stackable>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Segment basic style={{ height: '60%' }}>
                                <Header as='h3' icon>
                                    <Icon name='clipboard list' color='blue' size='small' />
                                    {t("Questionnaire")}
                                    <Header.Subheader>
                                        {t("Questionnaire Description", { givenName: givenName })}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Button
                                color='blue'
                                as={Link}
                                to="/questionnaire"
                                state={{
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode
                                }}
                            >
                                {t("Start Questionnaire")}
                            </Button>
                            <Divider hidden />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Segment basic style={{ height: '60%' }}>
                                <Header as='h3' icon>
                                    <Icon name='map signs' color='blue' size='small' />
                                    {t("Needs Known")}
                                    <Header.Subheader>
                                        {t("Needs Known Description", { givenName: givenName })}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Button
                                color='blue'
                                as={Link}
                                to="/needsbasedreferral"
                                state={{
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode,
                                    locationBasedConcepts: locationBasedConcepts,
                                }}>
                                {t("Show Offer")}
                            </Button>
                            <Divider hidden />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment textAlign='center' basic secondary>
                            <Segment basic style={{ height: '60%' }}>
                                <Header as='h3' icon>
                                    <Icon name='mail forward' color='blue' size='small' />
                                    {t("Direct Referral")}
                                    <Header.Subheader>
                                        {t("Direct Referral Description", { givenName: givenName })}
                                    </Header.Subheader>
                                </Header>
                            </Segment>
                            <Button
                                color='blue'
                                as={Link}
                                to="/directreferral"
                                state={{
                                    patientId: patientId,
                                    patientName: name,
                                    givenName: givenName,
                                    postalCode: postalCode
                                }} >
                                {t("Start Referral")}
                            </Button>
                            <Divider hidden />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Container>
            <Divider hidden />
        </Segment>
        </MediaContextProvider>
    }
}

function WithNavigateWrapper(props) {
    const navigate = useNavigate();
    const location = useLocation();
    return <ChooseReferralType {...props} navigate={navigate} location={location} />;
}

export default withTranslation()(WithNavigateWrapper);

