import React, { Component, Fragment } from 'react';
import { Grid, Segment, Icon, Feed, Header, Image, List, Button, Label, Loader, Menu, Divider, Form, Message, Modal } from 'semantic-ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import logogreen from '../images/state-green@2x.png';
import logored from '../images/state-red@2x.png';
import logoorange from '../images/state-orange@2x.png';
import logogrey from '../images/state-grey@2x.png';
import logoyellow from '../images/state-yellow@2x.png';
import { authHeader, authHeaderPatch, authHeaderSet } from '../helpers/auth-header.js';
import { getAge } from '../helpers/date-utils.js';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class Patient extends Component {

    constructor(props) {
        super(props);
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        const location = props.location || {};
        const patientId = location.state?.patientId || '';
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            patient: [],
            patientId: patientId,
            practitionerRole: [],
            practitionerRoleId: '',
            practitionerName: '',
            zipCode: '',
            encounters: [],
            procedures: [],
            lastEncounter: [],
            lastEncounterDate: null,
            lastQuestionnaireId: '',
            codableConcepts: [],
            codableConceptMappings: [],
            locationBasedCodableConcepts: [],
            loadingLocationConcepts: true,
            serviceRequests: [],
            organisations: [],
            socialNeeds: [],
            referralStatus: '',
            pageSize: 40,
            directReferral: false,
            consentGiven: false,
            services: [],
            chosenService: '',
            chosenSupportNeeds: '',
            serviceCodableConcept: '',
            encounterId: '',
            socialContextAnswer: ''
        }
    }

    retrieveLocation = () => {
        return fetch(this.config.LOCATIONS_URL + "?code=" + this.state.zipCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.setState({ location: location.uuid }, () => {
                    this.preloadLocationBasedCodableConcepts();
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    preloadLocationBasedCodableConcepts() {
        let codableTexts = [];
        this.setState({ loadingLocationConcepts: true }); // Set loading state to true while data is being fetched
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=150&system="
            + this.config.CODABLE_CONCEPT_SYSTEM
            + "&location=" + this.state.location, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ locationBasedCodableConcepts: codableTexts, loadingLocationConcepts: false }); // Set loading to false once data is loaded
            })
            .catch(error => {
                this.handleError(error);
                this.setState({ loadingLocationConcepts: false }); // Set loading to false in case of error
            });
    }

    loadCodableConcepts() {
        let codableTexts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ codableConcepts: codableTexts });
                return codableTexts;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConceptMappings() {
        let codableConceptMappings = [];
        return fetch(this.config.CODABLECONCEPTMAPPINGS_URL + "?pagesize=800", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConceptMappings;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let codableConceptUuid = concept.codableConcept.uuid;
                    codableConceptMappings.push({ uuid, codableConceptUuid });
                }
                this.setState({ codableConceptMappings: codableConceptMappings });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrievePatient = () => {
        return fetch(this.config.PATIENTS_URL + "/" + this.state.patientId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(patient => {
                this.setState({
                    patient: patient,
                    patientName: patient.givenName + " " + patient.familyName,
                    familyName: patient.familyName,
                    givenName: patient.givenName,
                    phone: patient.phone,
                    email: patient.email,
                    birthDate: moment(patient.birthdate).format('YYYY-MM-DD'),
                    identifier: patient.identifier
                });
                if (patient.address) {
                    this.retrieveAddress(patient.address.uuid);
                }
                this.retrieveProcedures();
                this.retrieveEncounters();
                this.retrieveServiceRequests();
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.setState({
                    zipCode: address.postalCode,
                    addressUuid: address.uuid
                }, () => {
                    this.retrieveLocation();
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveQuestionnaireResponse = (encounterId) => {
        return fetch(this.config.QUESTIONNAIRE_RESPONSES_URL + "?encounter=" + encounterId, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(responses => {
                let embedded = responses._embedded.questionnaireResponses;
                if (embedded && embedded.length === 1) {
                    let lastQuestionnaireId = embedded[0].uuid;
                    this.setState({
                        lastQuestionnaireId: lastQuestionnaireId
                    });
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveProcedures = () => {
        return fetch(this.config.PROCEDURES_URL + "?patient=" + this.state.patientId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(procedures => {
                let embedded = procedures._embedded.procedures;
                this.setState({
                    procedures: embedded
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveEncounters = () => {
        return fetch(this.config.ENCOUNTERS_URL + "?subject=" + this.state.patientId + "&pagesize=20", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(encounters => {
                let embedded = encounters._embedded.encounters;
                if (embedded && embedded.length > 0) {
                    let lastEncounterId = embedded[0].uuid;
                    let lastEncounterDate = embedded[0].date;
                    this.setState({
                        lastEncounter: lastEncounterId,
                        lastEncounterDate: lastEncounterDate
                    }, () => this.retrieveQuestionnaireResponse(lastEncounterId));
                }
                this.setState({
                    encounters: embedded
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceRequestConcepts = (serviceRequestId) => {
        return fetch(this.config.SERVICEREQUESTS_CONCEPTS_URL + "?serviceRequest=" + serviceRequestId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceRequests => {
                let codableConceptIds = [];
                let embedded = serviceRequests._embedded.serviceRequestCodableConcepts;
                for (var i = 0; i < embedded.length; i++) {
                    codableConceptIds.push(embedded[i].codableConcept.uuid);
                }
                return codableConceptIds;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getCodableConcept = (uuid) => {
        for (var i = 0; i < this.state.codableConcepts.length; i++) {
            if (uuid === this.state.codableConcepts[i].uuid) {
                return this.state.codableConcepts[i];
            }
        }
        return null;
    }

    addToNeeds = (codableConcepts) => {
        let socialNeeds = this.state.socialNeeds;
        for (var i = 0; i < codableConcepts.length; i++) {
            let codableConcept = codableConcepts[i];
            if (!socialNeeds.includes(codableConcept)) {
                socialNeeds.push(codableConcept);
            }
        }
        this.setState({
            socialNeeds: socialNeeds
        });

    }

    retrieveServiceRequests = () => {
        return fetch(this.config.SERVICEREQUESTS_URL + "?patient=" + this.state.patientId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceRequests => {
                let embedded = serviceRequests._embedded.serviceRequests;
                let currentComponent = this;
                for (var i = 0; i < embedded.length; i++) {
                    let serviceRequestId = embedded[i].uuid;
                    this.retrieveServiceRequestConcepts(serviceRequestId).then(function (codableConceptIds) {
                        let fullCodableConcepts = [];
                        for (var j = 0; j < codableConceptIds.length; j++) {
                            let codableConcept = currentComponent.getCodableConcept(codableConceptIds[j]);
                            if (codableConcept) {
                                fullCodableConcepts.push(codableConcept);
                            }
                        }
                        return fullCodableConcepts;
                    }).then(function (fullCodableConcepts) {
                        for (var i = 0; i < embedded.length; i++) {
                            if (embedded[i].uuid === serviceRequestId) {
                                embedded[i].codableConcepts = fullCodableConcepts;
                                break;
                            }
                        }
                        if (embedded[i].performer) {
                            currentComponent.retrieveAssignedHealthcareService(embedded[i].performer.uuid).then(function (assignedHealthcareService) {
                                embedded[i].performer = assignedHealthcareService;
                                currentComponent.addToNeeds(fullCodableConcepts);
                                currentComponent.setState({
                                    serviceRequests: embedded
                                }, () => currentComponent.setOverallStatusAndDetailedSupport());
                            })
                        }
                    })
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAssignedHealthcareService = (uuid) => {
        return fetch(this.config.ASSIGNED_HEALTHCARE_SERVICES_URL + "/" + uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    saveAddress = () => {
        return fetch(this.config.ADDRESSES_URL + "/" + this.state.addressUuid, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                postalCode: this.state.zipCode
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    savePatientDetails = () => {
        return fetch(this.config.PATIENTS_URL + "/" + this.state.patientId, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                name: this.state.givenName + " " + this.state.familyName,
                identifier: this.state.identifier,
                familyName: this.state.familyName,
                givenName: this.state.givenName,
                phone: this.state.phone,
                email: this.state.email,
                birthdate: this.state.birthDate
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.setState({
                    patientName: this.state.givenName + " " + this.state.familyName,
                    showEditPatientModal: false
                });
                this.saveAddress();
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    componentDidMount() {
        let component = this;
        let conceptPromise = this.loadCodableConcepts();
        if (conceptPromise) {
            conceptPromise.then(function (concepts) {
                component.loadCodableConceptMappings();
                component.setState({
                    practitionerRole: component.state.activeUser,
                    practitionerRoleId: component.state.activeUser.uuid,
                    practitionerName: component.state.activeUser.name
                },
                    () => {
                        component.retrievePatient()
                    });
            })
        }
    }

    getPhone() {
        if (this.state.phone) {
            return <p><Icon name='phone' color='blue' />{this.state.phone}</p>
        }
    }

    getMail() {
        if (this.state.email) {
            return <p><Icon name='mail' color='blue' />{this.state.email}</p>
        }
    }

    getZipCode() {
        if (this.state.zipCode) {
            return <p><Icon name='map pin' color='blue' />{this.state.zipCode}</p>
        }
    }

    getDate = (date) => {
        if (date) {
            return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit' }).format(Date.parse(date));
        } else {
            return <Loader active size='tiny' />;
        }
    }

    getPractitionerName = (practitionerName) => {
        if (practitionerName === this.state.practitionerName) {
            return 'U';
        } else {
            return practitionerName;
        }
    }

    getCodableConcepts = (serviceRequest) => {
        const { t } = this.props;
        if (serviceRequest.codableConcepts) {
            return <List>
                {serviceRequest.codableConcepts.slice(0, serviceRequest.codableConcepts.length).map((codableConcept, index) =>
                    <List.Item key={index}><List.Icon name='angle double right' color='blue' />{t(codableConcept.text)}</List.Item>
                )}
            </List>;
        }
    }

    getNeeds = () => {
        const { t } = this.props;
        if (this.state.socialNeeds && this.state.socialNeeds.length > 0) {
            return <List>
                {this.state.socialNeeds.slice(0, this.state.socialNeeds.length).map((socialNeed, index) =>
                    <List.Item key={index}><List.Icon name='angle double right' color='blue' key={index} />{t(socialNeed.text)}</List.Item>
                )}
            </List>;
        } else {
            return <Segment basic><Icon name="thumbs up" color="blue" />{t("No Support Needs", { givenName: this.state.givenName })}</Segment>
        }
    }

    setOverallStatusAndDetailedSupport = () => {
        let status = "REQUESTED";
        let serviceRequest = this.state.serviceRequests[0];
        if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
            status = this.config.SERVICE_REQUEST_STATUS_SUPPORT;
        } if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
            status = this.config.SERVICE_REQUEST_STATUS_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED) {
            status = this.config.SERVICE_REQUEST_STATUS_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_FORWARD) {
            status = "REQUESTED";
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SHOW) {
            status = this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_WAITING_LIST) {
            status = "REQUESTED";
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_PLANNED) {
            status = this.config.SERVICE_REQUEST_STATUS_PLANNED;
        }
        this.setState({
            referralStatus: status
        });
    }

    getRequestStatus = (serviceRequest) => {
        const { t } = this.props;
        let status = <Label basic image><img src={logoorange} alt="" />{t("Requested")}</Label>;
        if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_PLANNED) {
            status = <Label basic image><img src={logoyellow} alt="" />{t("Planned")}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_WAITING_LIST) {
            status = <Label basic image><img src={logoorange} alt="" />{t("Planned")}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
            status = <Label basic image><img src={logogreen} alt="" />{t("Supported")}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
            status = <Label basic image><img src={logogreen} alt="" />{t("Support Finished")}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_FORWARD) {
            status = <Label basic image><img src={logogrey} alt="" />{t("Referred")}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT) {
            status = <Label basic image><img src={logored} alt="" />{t("No Support")}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED) {
            status = <Label basic image><img src={logogreen} alt="" />{t("Already Supported")}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE) {
            status = <Label basic image><img src={logored} alt="" />{t("Not Reachable", { givenName: this.state.givenName })}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SHOW) {
            status = <Label basic image><img src={logored} alt="" />{t("No Show", { givenName: this.state.givenName })}</Label>;
        } else if (serviceRequest.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED) {
            status = <Label basic image><img src={logored} alt="" />{t("No Support Wanted", { givenName: this.state.givenName })}</Label>;
        }
        return status;
    }

    getStatusImage = () => {
        if (this.state.referralStatus) {
            let logo = logogreen;
            if (this.state.referralStatus === this.config.SERVICE_REQUEST_STATUS_PLANNED) {
                logo = logoyellow;
            } else if (this.state.referralStatus === this.config.SERVICE_REQUEST_STATUS_WAITING_LIST) {
                logo = logoorange;
            } else if (this.state.referralStatus === "REQUESTED") {
                logo = logoorange;
            } else if (this.state.referralStatus === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
                logo = logogreen;
            } else if (this.state.referralStatus === this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED) {
                logo = logogreen;
            } else if (this.state.referralStatus === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
                logo = logogreen;
            } else if (this.state.referralStatus === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT) {
                logo = logored;
            }
            return <Image circular src={logo} size='mini' />;
        }
    }

    getEncounterSummary = (encounter) => {
        const { t } = this.props;
        let procedure = null;
        for (var i = 0; i < this.state.procedures.length; i++) {
            let currentProcedure = this.state.procedures[i];
            if (currentProcedure.encounter.uuid === encounter.uuid) {
                procedure = currentProcedure;
                break;
            }
        }
        if (procedure) {
            if (procedure.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT) {
                return <Fragment>{t("Encounter Support", { practitionerName: this.getPractitionerName(encounter.participant.name), givenName: this.state.givenName, serviceName: procedure.healthcareService.name })}</Fragment>
            } if (procedure.status === this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED) {
                return <Fragment>{t("Encounter Support Finished", { practitionerName: this.getPractitionerName(encounter.participant.name), givenName: this.state.givenName, serviceName: procedure.healthcareService.name })}</Fragment>
            } if (procedure.status === this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT) {
                return <Fragment>{t("Encounter No Support", { practitionerName: this.getPractitionerName(encounter.participant.name), givenName: this.state.givenName, serviceName: procedure.healthcareService.name })}</Fragment>
            } if (procedure.status === this.config.SERVICE_REQUEST_STATUS_FORWARD) {
                return <Fragment>{t("Encounter Referred", { practitionerName: this.getPractitionerName(encounter.participant.name), givenName: this.state.givenName, serviceName: procedure.healthcareService.name })}</Fragment>
            } else if (procedure.status === this.config.SERVICE_REQUEST_STATUS_PLANNED) {
                return <Fragment>{t("Encounter Conversation Planned", { practitionerName: this.getPractitionerName(encounter.participant.name), givenName: this.state.givenName, serviceName: procedure.healthcareService.name })}</Fragment>
            } else if (procedure.status === this.config.SERVICE_REQUEST_STATUS_WAITING_LIST) {
                return <Fragment>{t("Encounter Waiting List", { practitionerName: this.getPractitionerName(encounter.participant.name), givenName: this.state.givenName, serviceName: procedure.healthcareService.name })}</Fragment>
            } else if (procedure.status === this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED) {
                return <Fragment>{t("Encounter Already Supported", { givenName: this.state.givenName })}</Fragment>
            } else if (procedure.status === this.config.SERVICE_REQUEST_STATUS_NO_SHOW) {
                return <Fragment>{t("Encounter No Show", { givenName: this.state.givenName, serviceName: procedure.healthcareService.name })}</Fragment>
            }
        } else {
            return <Fragment>{t("Encounter Conversation", { practitionerName: this.getPractitionerName(encounter.participant.name), givenName: this.state.givenName })}</Fragment>
        }
    }

    getHealthcareServiceName = (serviceRequest) => {
        if (serviceRequest && serviceRequest.performer && serviceRequest.performer.healthcareService) {
            return serviceRequest.performer.healthcareService.name;
        }
    }

    getActualReferrals = () => {
        let actualReferrals = [];
        for (var i = 0; i < this.state.serviceRequests.length; i++) {
            if (this.state.serviceRequests[i].intent !== this.config.SERVICE_REQUEST_INTENT_SIGNPOSTING) {
                actualReferrals.push(this.state.serviceRequests[i]);
            }
        }
        return actualReferrals;
    }

    getSignposts = () => {
        let signposts = [];
        for (var i = 0; i < this.state.serviceRequests.length; i++) {
            if (this.state.serviceRequests[i].intent === this.config.SERVICE_REQUEST_INTENT_SIGNPOSTING) {
                signposts.push(this.state.serviceRequests[i]);
            }
        }
        return signposts;
    }

    getReferrals = () => {
        const { t } = this.props;
        let actualReferrals = this.getActualReferrals();
        if (actualReferrals.length > 0) {
            return <Fragment><Header as='h2' icon textAlign='center' size='small'>
                <Icon name='mail forward' color='blue' />
                <Header.Content>{t("Referrals")}</Header.Content>
            </Header>
                <Feed size='small'>
                    {actualReferrals.slice(0, actualReferrals.length).map((serviceRequest, index) =>
                        <Segment basic key={index}>
                            <Header>{this.getHealthcareServiceName(serviceRequest)} </Header>
                            <List>
                                <List.Item>
                                    <List.Icon name='hand paper' color='blue' />
                                    <List.Content>{t("Support Request For")} {this.getCodableConcepts(serviceRequest)}</List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Content>{this.getRequestStatus(serviceRequest)}</List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    )}
                </Feed>
            </Fragment>;
        }
    }

    getSignposting = () => {
        const { t } = this.props;
        let signposts = this.getSignposts();
        if (signposts.length > 0) {
            return <Fragment><Header as='h2' icon textAlign='center' size='small'>
                <Icon name='info circle' color='blue' />
                <Header.Content>{t("Informed About")}</Header.Content>
            </Header>
                <Feed size='small'>
                    {signposts.slice(0, signposts.length).map((serviceRequest, index) =>
                        <Segment basic key={index}>
                            <Header>{this.getHealthcareServiceName(serviceRequest)} </Header>
                            <List>
                                <List.Item>
                                    <List.Icon name='file pdf' color='blue' />
                                    <List.Content>{t("Patient Informed", { givenName: this.state.givenName })} {this.getCodableConcepts(serviceRequest)}</List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    )}
                </Feed>
            </Fragment>;
        }
    }

    showEncounters = () => {
        const { t } = this.props;
        if (this.state.encounters && this.state.encounters.length > 0) {
            return <Fragment>{this.state.encounters.slice(0, this.state.encounters.length).map((encounter, index) =>
                <Feed.Event key={index}>
                    <Feed.Label>
                        <Icon name='talk' color='blue' />
                    </Feed.Label>
                    <Feed.Content>
                        <Feed.Date>{this.getDate(encounter.date)}</Feed.Date>
                        <Feed.Summary>
                            {this.getEncounterSummary(encounter)}
                        </Feed.Summary>
                    </Feed.Content>
                </Feed.Event>
            )}</Fragment>
        } else {
            return <Message>{t("No Contact")}</Message>
        }
    }


    getDashboard = () => {
        const { t } = this.props;
        return <Segment>
            <Menu text >
                <Menu.Item position='right'>
                    <Button as={Link}
                        to="/choosereferral"
                        state={{
                            patientId: this.state.patientId,
                            patientName: this.state.givenName + ' ' + this.state.familyName,
                            patientGivenName: this.state.givenName,
                            patientPostalCode: this.state.zipCode,
                            patientEmail: this.state.email,
                            locationBasedConcepts: this.state.locationBasedCodableConcepts,
                        }} primary icon size='mini' labelPosition='left'
                        disabled={this.state.locationBasedCodableConcepts.length === 0}>
                        <Icon name='mail forward' />{this.state.loadingLocationConcepts ? (
                            <Loader active inline size="small" />
                        ) : (
                            <>
                                <Icon name='mail forward' /> {t("New Referral")}
                            </>
                        )}</Button>
                </Menu.Item>
            </Menu>
            <Divider />
            <Grid columns={2} divided stackable>
                <Grid.Column width={8}>
                    <Header as='h2' icon textAlign='center' size='small'>
                        <Icon name='history' color='blue' />
                        <Header.Content>{t("History")}</Header.Content>
                    </Header>
                    <Feed size='small'>
                        {this.showEncounters()}
                    </Feed>
                </Grid.Column>
                <Grid.Column width={8}>
                    {this.getReferrals()}
                    {this.getSignposting()}
                </Grid.Column>
            </Grid>
        </Segment>;
    }

    getLastEncounterInfo = () => {
        const { t } = this.props;
        if (this.state.lastEncounterDate) {
            return <Fragment>{t("Last Encounter")} {this.getDate(this.state.lastEncounterDate)}</Fragment>
        }
    }

    handleOpenEditPatientModal = () => {
        this.setState({
            showEditPatientModal: true
        });
    }

    handleCloseEditPatientModal = () => {
        this.setState({ showEditPatientModal: false });
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })


    getEditPatientModal = () => {
        const { t } = this.props;
        const { identifier, givenName, familyName, phone, email, birthDate, zipCode } = this.state;
        if (this.state.showEditPatientModal) {
            return <Modal
                open={this.state.showEditPatientModal}
                onClose={this.handleCloseHealthcareServiceModal}>
                <Modal.Header>{t("Edit Personal Data")}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form onSubmit={this.handleRegisterPatient}>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    required
                                    fluid
                                    label={t("Given Name")}
                                    placeholder={t("Given Name")}
                                    name='givenName'
                                    value={givenName}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    required
                                    fluid
                                    label={t("Family Name")}
                                    placeholder={t("Family Name")}
                                    name='familyName'
                                    value={familyName}
                                    onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    type="date"
                                    required
                                    fluid
                                    label={t("Date of Birth")}
                                    placeholder={t("Date of Birth")}
                                    name='birthDate'
                                    value={birthDate}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    fluid
                                    label={t("SSN")}
                                    placeholder={t("SSN")}
                                    name='identifier'
                                    value={identifier}
                                    onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group widths={16}>
                                <Form.Input
                                    width={7}
                                    required
                                    fluid
                                    label={t("Telephone Number")}
                                    placeholder={t("Telephone Number")}
                                    name='phone'
                                    value={phone}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    width={3}
                                    fluid
                                    required
                                    label={t("Postal Code")}
                                    placeholder={t("Postal Code")}
                                    name='zipCode'
                                    value={zipCode}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    width={6}
                                    fluid
                                    label={t("Email")}
                                    placeholder={t("Email")}
                                    name='email'
                                    value={email}
                                    onChange={this.handleChange} />
                            </Form.Group>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon labelPosition='right' color='blue' onClick={this.savePatientDetails}>
                        {t("Save")}
                        <Icon name='save' />
                    </Button>
                    <Button color='blue' onClick={this.handleCloseEditPatientModal}>
                        <Icon name='close' />{t("Cancel")}
                    </Button>
                </Modal.Actions>
            </Modal>
        }
    }

    renderAge = (age) => {
        const { t } = this.props;
        if (age) {
            return <Header size='small'>{age} {t("Years")}</Header>;
        }
    }

    render() {
        const { t } = this.props;
        let age = getAge(this.state.birthDate);
        return (
            <Fragment>
                {this.getEditPatientModal()}
                <Segment>
                    <Grid columns={3} divided stackable>
                        <Grid.Column width={5}>
                            <Segment textAlign='center' basic>
                                <Header size='large'>
                                    {this.getStatusImage()}{this.state.patientName}
                                    <Header.Subheader>{this.getLastEncounterInfo()}</Header.Subheader>
                                </Header>
                                {this.renderAge(age)}
                                <Button circular icon='edit' color='orange'
                                    size='mini'
                                    onClick={this.handleOpenEditPatientModal} />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Segment basic >
                                <Header>{t("Support Needs")}</Header>
                                {this.getNeeds()}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Segment basic>
                                <Header>{t("Contact")} {this.state.givenName}</Header>
                                {this.getPhone()}
                                {this.getMail()}
                                {this.getZipCode()}
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
                {this.getDashboard()}
            </Fragment>
        );
    }

}

function WithNavigateWrapper(props) {
    const navigate = useNavigate();
    const location = useLocation();
    return <Patient {...props} navigate={navigate} location={location} />;
}

export default withTranslation()(WithNavigateWrapper);

