import React, { Component } from 'react';
import { Button, Icon, Header, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import AuthenticationService from '../api/AuthenticationService.js';
import { withTranslation } from 'react-i18next';

class ReferralCompleted extends Component {

    constructor() {
        super();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser()
        }
    }

    render() {
        const { t } = this.props;
        const patientLinkState = {
            activeUser: this.state.activeUser,
            patientId: this.props.patientId,
        };
        if (this.props.requestedReferral) {
            return <Segment basic>
                <Header as='h1'>{t("Referral Started")}</Header>
                <Segment>
                    <p>{t("Referral Started S1")}</p>
                    <p>{t("Referral Started S2")}</p>
                </Segment>
                <Button
                    as={Link}
                    to="/patient"
                    state={patientLinkState}
                    icon
                    labelPosition='right'
                    color='blue'
                    floated='right'
                >
                    {t("To Overview")}<Icon name='home' />
                </Button>
            </Segment>;
        } if (this.props.requestedLeaflets) {
            return <Segment basic>
                <Header as='h1'>{t("Information Sent")}</Header>
                <Segment>
                    <p>{t("Information Sent S1")}</p>
                    <p>{t("Information Sent S2")}</p>
                </Segment>
                <Button
                    as={Link}
                    to="/patient"
                    state={patientLinkState}
                    icon
                    labelPosition='right'
                    color='blue'
                    floated='right'
                >
                    {t("To Overview")}<Icon name='home' />
                </Button>
            </Segment>;
        } else {
            return <Segment basic>
                <Header as='h1'>{t("Questionnaire Ended")}</Header>
                <Segment>
                    <p>{t("Questionnaire Ended S1")}</p>
                </Segment>
                <Button
                    as={Link}
                    to="/patient"
                    state={patientLinkState}
                    icon
                    labelPosition='right'
                    color='blue'
                    floated='right'
                >
                    {t("To Overview")}<Icon name='home' />
                </Button>
            </Segment>;
        }
    }
}

export default withTranslation()(ReferralCompleted);
