import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Icon, Header, Segment, Message, Button, Checkbox, Divider, Grid, Container } from 'semantic-ui-react';
import { authHeader } from '../helpers/auth-header.js';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { withTranslation } from 'react-i18next';
import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});

const { Media, MediaContextProvider } = AppMedia;

class RegisterPatientForm extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            patient: null,
            identifier: '',
            familyName: '',
            givenName: '',
            phone: '',
            email: '',
            gender: '',
            birthDate: '',
            patientCreationError: false,
            patientCreated: false,
            errorMsg: '',
            postcode: '',
            addressId: '',
            patientId: null,
            consentGiven: false
        }
    }

    createPatient = (addressId) => {
        let identifier = this.state.identifier;
        if (identifier === "") {
            identifier = null;
        }
        return fetch(this.config.PATIENTS_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                name: this.state.givenName + " " + this.state.familyName,
                identifier: identifier,
                familyName: this.state.familyName,
                givenName: this.state.givenName,
                phone: this.state.phone,
                email: this.state.email,
                birthdate: this.state.birthDate,
                address: addressId,
                byGeneralPractitioner: true
            })
        }).then(response => {
            if (!response.ok) {
                this.setState({ patientCreationError: true })
            } else {
                return response.json();
            }
        }).then(patientId => {
            this.setState({
                patientId: patientId.id,
                patientCreated: true
            });
        }).catch(error => {
            this.handleError(error);
        });
    }

    createAddressAndPatient = () => {
        return fetch(this.config.ADDRESSES_URL, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                postalCode: this.state.postcode
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                return response.json();
            }
        }).then(json => {
            let addressId = json.id;
            this.setState({ addressId: addressId }, () => {
                this.createPatient(addressId);
            });
        }).catch(error => {
            this.handleError(error);
        });
    }

    handleError = (error) => {
        console.log(error.message);
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    handleClick = (e, { name, value }) => {
        this.setState({ [name]: false })
    }

    handleRegisterPatient = () => {
        this.createAddressAndPatient();
    }

    getErrorMessage() {
        const { t } = this.props;
        if (this.state.patientCreationError) {
            return <Message
                error
                header={t("Patient Registration Failed")}
                content={t("Patient Registration Failed")}
            />
        }
    }

    checkConsentGiven = () => {
        this.setState({ consentGiven: !this.state.consentGiven })
    }

    getNextButton = () => {
        const { t } = this.props;
        return <Button icon labelPosition='right' color='blue'
            disabled={!this.state.consentGiven}>
            {t("Register")}
            <Icon name='save' />
        </Button>;
    }

    render() {
        const { t } = this.props;
        const { identifier, givenName, familyName, phone, email, birthDate, postcode } = this.state
        if (this.state.patientCreated) {
            this.props.navigate('/choosereferral', {
                state: {
                    patientId: this.state.patientId,
                    patientName: givenName + ' ' + familyName,
                    patientGivenName: givenName,
                    patientPostalCode: postcode,
                    patientEmail: email,
                },
            });
        }
        return <Grid columns={3}>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={14}>
                <Segment>
                    <Header as='h1'>{t("Registration New Patient")}</Header>
                    <Segment>
                        <Form onSubmit={this.handleRegisterPatient}>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    required
                                    fluid
                                    label={t("Given Name")}
                                    placeholder={t("Given Name")}
                                    name='givenName'
                                    value={givenName}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    required
                                    fluid
                                    label={t("Family Name")}
                                    placeholder={t("Family Name")}
                                    name='familyName'
                                    value={familyName}
                                    onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    type="date"
                                    required
                                    fluid
                                    label={t("Date of Birth")}
                                    placeholder={t("Date of Birth")}
                                    name='birthDate'
                                    value={birthDate}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    fluid
                                    label={t("SSN")}
                                    placeholder={t("SSN")}
                                    name='identifier'
                                    value={identifier}
                                    onChange={this.handleChange} />
                            </Form.Group>
                            <Form.Group widths={16}>
                                <Form.Input
                                    width={7}
                                    required
                                    fluid
                                    label={t("Telephone Number")}
                                    placeholder={t("Telephone Number")}
                                    name='phone'
                                    value={phone}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    width={3}
                                    fluid
                                    required
                                    label={t("Postal Code")}
                                    placeholder={t("Postal Code")}
                                    name='postcode'
                                    value={postcode}
                                    onChange={this.handleChange} />
                                <Form.Input
                                    width={6}
                                    fluid
                                    label={t("Email")}
                                    placeholder={t("Email")}
                                    name='email'
                                    value={email}
                                    onChange={this.handleChange} />
                            </Form.Group>
                            <Divider hidden />
                            <Form.Group widths={16}>
                                <Divider hidden />
                                <Form.Field width={10}
                                    fluid>
                                    <Checkbox checked={this.state.consentGiven} onClick={this.checkConsentGiven}
                                        label={t("Patient Consent Zipster")} />
                                </Form.Field>
                            </Form.Group>
                            <MediaContextProvider>
                            <Container as={Media} lessThan="computer" fluid>
                                <Divider hidden />
                            </Container>
                            </MediaContextProvider>
                            <div>
                                {this.getNextButton()}
                            </div>
                        </Form>
                    </Segment>
                    {this.getErrorMessage()}
                </Segment>
            </Grid.Column>
            <Grid.Column width={1}></Grid.Column>
        </Grid>;
    }
}

function WithNavigateWrapper(props) {
    const navigate = useNavigate();
    return <RegisterPatientForm {...props} navigate={navigate} />;
  }
  
  export default withTranslation()(WithNavigateWrapper);

