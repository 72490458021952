import React, { Component, Fragment } from 'react';
import { Divider, Grid, Menu, Icon, Segment, Header, Loader, Dropdown, Pagination, Button, Modal, Label, GridColumn, List, Popup, Image } from 'semantic-ui-react';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import ServiceUtils from '../utils/ServiceUtils.js';
import { authHeaderSet, anonHeader } from '../helpers/auth-header.js';
import { authHeader } from '../helpers/auth-header.js';
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from 'react-i18next';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import markerIconOrange from '../images/decorator-circle-orange.svg';
import markerIconBlue from '../images/decorator-circle-blue.svg';
import zipsterLogo from '../images/logo-colour.png';
import {
    setKey,
    fromAddress
} from "react-geocode";

// Set the API key for Geocode
setKey('AIzaSyAqE43ZJgJp2moxHZmaWq49YSuaONCau0s');

class PublicServices extends Component {

    BXL_SOCIAL = "bxlSocial";
    SOCIALE_KAART = "socialeKaart";

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            services: [],
            codableConcepts: [],
            codableConceptsPG: [],
            serviceName: '',
            codableConcept: '',
            activePage: 1,
            serviceRequestPageSize: 250,
            totalPages: 1,
            totalElements: 1,
            itemsPerPage: 15,
            searchLocationId: '609d31d6-f134-444a-8022-dfeb64e395ea',
            serviceRetrievalOngoing: false,
            compactView: false,
            showHealthcareServiceModal: false,
            markers: [],
            recaptchaValue: 'test',
            showChatbot: true,
            toggleChatbot: false,
            isModalOpen: false,
        }
    }

    // Open Modal
    handleOpenModal = () => this.setState({ isModalOpen: true });

    // Close Modal
    handleCloseModal = () => this.setState({ isModalOpen: false });

    retrieveServiceAddress = async (addressUuid) => {
        if (addressUuid) {
            const uuid = addressUuid.uuid;
            try {
                const response = await fetch(`${this.config.ADDRESSES_URL}/${uuid}`, { method: 'GET', headers: authHeader() });
                if (!response.ok) return this.handleResponseError(response);
                return await response.json();
            } catch (error) {
                this.handleError(error);
            }
        }
    }

    loadCodableConcepts = async () => {
        try {
            const response = await fetch(`${this.config.CODABLECONCEPTS_URL}?pagesize=200&system=${this.config.CODABLE_CONCEPT_SYSTEM}`, { method: 'GET', headers: authHeaderSet() });
            if (!response.ok) return this.handleResponseError(response);
            const concepts = await response.json();
            this.setState({ codableConcepts: concepts._embedded.codableConcepts });
        } catch (error) {
            this.handleError(error);
        }
    }

    loadCodableConceptsPG = async () => {
        try {
            const response = await fetch(`${this.config.CODABLECONCEPTS_URL}?pagesize=10&system=${this.config.CODABLE_CONCEPT_PG_SYSTEM}`, {
                method: 'GET',
                headers: authHeaderSet(),
            });

            if (!response.ok) {
                return this.handleResponseError(response);
            }

            const concepts = await response.json();
            this.setState({ codableConceptsPG: concepts._embedded.codableConcepts });
        } catch (error) {
            this.handleError(error);
        }
    };

    getServiceDetailsBxlSocial = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsSocialeKaart = (serviceId) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetails = (reference) => {
        let bxlSocialId = ServiceUtils.getBxlSocialReference(reference);
        if (bxlSocialId) {
            let serviceDetails = this.getServiceDetailsBxlSocial(bxlSocialId);
            return serviceDetails;
        } else {
            let serviceDetails = this.getServiceDetailsSocialeKaart(reference);
            return serviceDetails;
        }
    }

    getBxlSocialDetails = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                let healthcareService = this.state.healthcareService;
                healthcareService.serviceDetails = serviceDetails;
                this.setState({
                    healthcareService: healthcareService
                })
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getSocialeKaartLeaflet = (healthcareService) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + healthcareService.socialeKaartReference, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                healthcareService.serviceDetails = leaflet;
                this.setState({
                    healthcareService: healthcareService
                })
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getMailableResource = (healthcareService) => {
        return fetch(this.config.MAILABLE_RESOURCES_URL + "?healthcareService=" + healthcareService.uuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(mailableResources => {
                let resources = mailableResources._embedded.mailableResources;
                return resources;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServices = async () => {
        try {
            // Fetch services when a new search/filter is applied
            let params = new URLSearchParams({
                pagesize: this.state.serviceRequestPageSize,
            });

            if (this.state.serviceName && this.state.serviceName.length > 1) {
                params.append('name', this.state.serviceName);
            }
            if (this.state.codableConcept) {
                params.append('codableConcept', this.state.codableConcept);
            }
            if (this.state.searchLocationId && this.state.searchLocationId !== this.config.REGION_VLAANDEREN_ID) {
                params.append('location', this.state.searchLocationId);
            }

            // Fetch services from the API
            const response = await fetch(`${this.config.HEALTHCARESERVICES_URL}?${params.toString()}`, {
                method: 'GET',
                headers: authHeaderSet(),
            });

            if (!response.ok) {
                this.handleResponseError(response);
                return;
            }

            const services = await response.json();
            const { _embedded } = services;

            // Filter out services without an address
            const fetchedServices = _embedded.healthcareServices || [];
            const filteredServices = fetchedServices.filter(service => service.address);

            // Update state with the filtered services (do not paginate yet)
            this.setState({
                services: filteredServices,
                totalPages: Math.ceil(filteredServices.length / this.state.itemsPerPage),
                totalElements: filteredServices.length,
                serviceRetrievalOngoing: true,
            }, () => {
                this.paginateServices(); // Paginate the freshly fetched services
            });
        } catch (error) {
            this.handleError(error);
        }
    };

    paginateServices = async () => {
        const { activePage, itemsPerPage, services } = this.state;
        const startIndex = (activePage - 1) * itemsPerPage;
        let paginatedServices = services.slice(startIndex, startIndex + itemsPerPage);
        let validServices = [];

        // Keep fetching until we fill up the 10 valid Antwerp-based services
        let fetchCount = 0;
        while (validServices.length < itemsPerPage && fetchCount < services.length) {
            // Fetch more services if needed, based on pagination and filtering
            paginatedServices = services.slice(startIndex + fetchCount, startIndex + fetchCount + itemsPerPage);

            // Fetch address details for each service and filter by Antwerp region
            const updatedServices = await Promise.all(paginatedServices.map(async (service) => {
                if (service.address) {
                    service.address = await this.retrieveServiceAddress(service.address) || service.address;

                    // Log postalCode for debugging
                    console.log(`Service name: ${service.name}, PostalCode: ${service.address.postalCode}`);

                    // Check if the postalCode is exactly 2160 (Wilrijk)
                    if (service.address.postalCode && service.address.postalCode.toString() === '2610') {
                        return service;
                    }
                }
                return null; // Return null for services not in Antwerp region
            }));

            // Add valid services to the list
            validServices = [...validServices, ...updatedServices.filter(service => service !== null)];

            // Increment the fetchCount to get the next batch of services
            fetchCount += itemsPerPage;
        }

        // Ensure we don't go over the itemsPerPage limit
        validServices = validServices.slice(0, itemsPerPage);

        this.setState({
            paginatedServices: validServices, // Store the paginated services with address details
        });

        this.fetchMarkers(validServices); // Fetch markers for the filtered services
    };


    handlePaginationChange = (e, { activePage }) => {
        // Only paginate locally when the page is changed
        this.setState({ activePage }, () => {
            this.paginateServices();
        });
    };


    componentDidUpdate() {
        if (this.state.searchLocationId && this.state.services.length === 0 && !this.state.serviceRetrievalOngoing) {
            this.retrieveServices();
            console.log("Component did update, services retrieved");
        }
        console.log("Component did update");
    }

    componentDidMount() {
        if (this.state.codableConcepts.length === 0) {
            this.loadCodableConcepts();
            this.loadCodableConceptsPG();
        }
    }

    handleChange = (e, { name, value }) => {
        clearTimeout(this.debounceTimeout);  // Clear any previous timeout
        this.debounceTimeout = setTimeout(() => {
            this.setState({ [name]: value, activePage: 1 }, () => {
                this.retrieveServices();
            });
        }, 500);  // Delay of 500ms before calling the API
    };

    handleRegionChange = (e, { value }) => this.setState({ value })

    handleDropdownChange = (e, { value }) => {
        // When a new dropdown value is selected, fetch the services again
        this.setState({ codableConcept: value, activePage: 1 }, () => {
            this.retrieveServices();
        });
    };

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError = (error) => {
        console.error('Error:', error.message);
    }

    fetchMarkers = async (paginatedServices) => {
        console.log('fetchMarkers called with services:', paginatedServices);

        if (paginatedServices.length === 0) {
            console.log('Services array is empty');
            return;
        }

        const newMarkers = [];
        for (const service of paginatedServices) {
            if (service.address && service.address.lines) {
                const { lines, postalCode, city } = service.address;
                const addressLine = `${lines} ${postalCode} ${city}`;
                console.log(`Fetching coordinates for address: ${addressLine}`);
                try {
                    const response = await fromAddress(addressLine);
                    if (response.results.length > 0) {
                        const { lat, lng } = response.results[0].geometry.location;
                        newMarkers.push({ lat, lng, service });
                    } else {
                        console.error('No results found for address:', addressLine);
                    }
                } catch (error) {
                    console.error('Error getting location for address:', addressLine, error);
                }
            }
        }
        this.setState({ markers: newMarkers });
    };

    indexToChar = (index) => {
        // Convert index to character (A-Z)
        return String.fromCharCode(65 + index);
    }

    showServiceType = (serviceType) => {
        if (serviceType) {
            return <Label size='mini'>{serviceType}</Label>;
        }
    }

    ////// SERVICE RENDERING ELEMENTS //////

    // ZIPSTER AS SOURCE //

    showZipsterServiceForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showZipsterService(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showZipsterService(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    // SOCIALE KAART OR BXL SOCIAL AS SOURCE //

    showExternalServiceInfoForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showExternalServiceInfo(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showExternalServiceInfo(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    getServiceBody = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            return this.showExternalServiceInfoForMode(healthcareService);
        } else {
            return this.showZipsterServiceForMode(healthcareService);
        }
    }

    // MODAL OPERATIONS //

    handleOpenHealthcareServiceModal = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            }, () => {
                let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
                if (bxlSocialId) {
                    this.getBxlSocialDetails(bxlSocialId);
                } else {
                    this.getSocialeKaartLeaflet(healthcareService);
                }
            })
        } else {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            });
        }
    }

    handleCloseHealthcareServiceModal = () => {
        this.setState({ showHealthcareServiceModal: false });
    }

    getHealthcareServiceModal = () => {
        const { t } = this.props;
        if (this.state.healthcareService) {
            return <Modal
                open={this.state.showHealthcareServiceModal}
                onClose={this.handleCloseHealthcareServiceModal}>
                <Modal.Header>
                    {ServiceUtils.getSocialeKaartLabel(this.state.healthcareService, this.state.showHealthcareServiceModal, this.props)}
                    {this.state.healthcareService.name}
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {this.getServiceBody(this.state.healthcareService)}
                        {ServiceUtils.getSocialeKaartButton(this.state.healthcareService)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={this.handleCloseHealthcareServiceModal}>
                        <Icon name='close' />{t("Close Window")}
                    </Button>
                </Modal.Actions>
            </Modal>
        }
    }

    getExtendedServiceOverview = () => {
        return <Grid columns={1} padded stackable>
            {this.state.services.slice(0, this.state.services.length).map((service, index) =>
                <Grid.Column width={16} key={index}>
                    <Header size='large' attached='top' inverted style={{ backgroundColor: '#385773' }}>
                        {ServiceUtils.getSocialeKaartLabel(service, this.state.showHealthcareServiceModal, this.props)}<Header.Content>{service.name}</Header.Content></Header>
                    <Segment raised attached>
                        {this.getServiceBody(service)}
                    </Segment>
                </Grid.Column>
            )}
        </Grid>;
    }

    getReferralIcon = (service) => {
        //if (service.referralMethod === 'email') {
        //    return <Icon color='blue' name='mail forward' />;
        //} else {
        //    return <Icon color='blue' name='address card' />;
        //}
        return;
    }

    getListItem = (service, index) => {
        const { hoveredService } = this.state;  // Track which service is hovered

        const labelColor = hoveredService === index ? 'blue' : 'orange';  // Change color based on hover

        if (service.type) {
            return (
                <Popup
                    basic
                    position='left center'
                    content={service.type}
                    size='small'
                    trigger={
                        <List.Item
                            key={index}
                            onMouseEnter={() => this.setState({ hoveredService: index })}  // Track hover enter
                            onMouseLeave={() => this.setState({ hoveredService: null })}   // Reset hover state on leave
                            onClick={() => this.handleOpenHealthcareServiceModal(service)}
                        >
                            <Label size='large' circular color={labelColor} key={index}>
                                {this.indexToChar(index)}
                            </Label>
                            <Label size='large'>{this.getReferralIcon(service)} {service.name}</Label>
                        </List.Item>
                    }
                />
            );
        } else {
            return (
                <List.Item
                    key={index}
                    onMouseEnter={() => this.setState({ hoveredService: index })}  // Track hover enter
                    onMouseLeave={() => this.setState({ hoveredService: null })}   // Reset hover state on leave
                    onClick={() => this.handleOpenHealthcareServiceModal(service)}
                >
                    <Label size='large' circular color={labelColor} key={index}>
                        {this.indexToChar(index)}
                    </Label>
                    <Label size='large'>{this.getReferralIcon(service)} {service.name}</Label>
                </List.Item>
            );
        }
    };

    getSelectedCodableConcept = () => {
        if (this.state.codableConcept) {
            return <Header>{this.state.codableConcept.text}</Header>;
        }
    }

    getList = () => {
        const { paginatedServices, totalPages } = this.state;
        if (paginatedServices && paginatedServices.length > 0) {
            return (
                <Fragment>
                    <List selection animated>
                        {paginatedServices.map((service, index) =>
                            this.getListItem(service, index)
                        )}
                    </List>
                    <Segment basic textAlign='center'>
                        <Pagination
                            activePage={this.state.activePage}
                            onPageChange={this.handlePaginationChange}
                            size='mini'
                            totalPages={totalPages}
                            textAlign='center'
                        />
                    </Segment>
                </Fragment>
            );
        } else {
            return <Loader active inline='centered' size='large' />;
        }
    };


    getServicesMap = () => {
        const { markers } = this.state;

        const mapStyles = {
            height: '100vh',
            width: '100%',
        };

        const defaultCenter = {
            lat: 51.17743190199286, // Default center: Valaar
            lng: 4.382273411267179
        };

        return (
            <Fragment>
                {this.getHealthcareServiceModal()}
                <Grid columns={16}>
                    <GridColumn width={12}>
                        <Segment basic>
                            <LoadScript googleMapsApiKey='AIzaSyAqE43ZJgJp2moxHZmaWq49YSuaONCau0s' libraries={['places']}
                                loadingElement={<div style={{ height: `100%` }} />}
                                async defer>
                                <GoogleMap
                                    mapContainerStyle={mapStyles}
                                    zoom={14}
                                    center={defaultCenter}
                                    mapTypeId='roadmap'
                                >
                                    {markers.map((marker, index) => (
                                        <Marker
                                            key={index}
                                            position={marker}
                                            label={{
                                                text: this.indexToChar(index),  // Keep the label text
                                                color: this.state.hoveredService === index ? 'white' : 'black',  // White text if hovered, otherwise black
                                                fontSize: '16px',  // Adjust the font size as needed
                                                fontWeight: 'bold',  // You can make the text bold if you prefer
                                            }}
                                            icon={this.state.hoveredService === index ? markerIconBlue : markerIconOrange}  // Blue marker if hovered
                                            onClick={() => this.handleOpenHealthcareServiceModal(marker.service)}
                                        />
                                    ))}
                                </GoogleMap>
                        </LoadScript>
                    </Segment>
                </GridColumn>
                <GridColumn width={4}>
                    <Segment basic>
                        {this.getList()}
                    </Segment>
                </GridColumn>
            </Grid>
            </Fragment >
        );
    }

    getServices = () => {
        if (this.state.services) {
            return this.getServicesMap();
        } else {
            return <Loader active inline='centered' size='large' />;
        }
    }

    getOptions = () => {
        const { t } = this.props;
        let options = [];
        for (var i = 0; i < this.state.codableConcepts.length; i++) {
            let codableConcept = this.state.codableConcepts[i];
            let option = { key: i, text: t(codableConcept.text), value: codableConcept.uuid };
            options.push(option);
        }
        return options;
    }

    getServicesPG = (ax) => {
        const { codableConceptsPG } = this.state;
        if (ax) {
            for (var i = 0; i < codableConceptsPG.length; i++) {
                let concept = codableConceptsPG[i];
                if (concept.text === ax) {
                    this.setState({ codableConcept: concept.uuid, activePage: 1 }, () => this.retrieveServices());
                    return;
                }
            }
        }
    }

    getButtons = () => {
        return <Segment basic>
            <Button size='medium' color='red' onClick={this.getServicesPG.bind(this, 'Lichaamsfuncties')}>Lichaamsfuncties</Button>
            <Button size='medium' color='violet' onClick={this.getServicesPG.bind(this, 'Mentaal Welbevinden')}>Mentaal Welbevinden</Button>
            <Button size='medium' color='purple' onClick={this.getServicesPG.bind(this, 'Zingeving')}>Zingeving</Button>
            <Button size='medium' color='orange' onClick={this.getServicesPG.bind(this, 'Kwaliteit van Leven')}>Kwaliteit van Leven</Button>
            <Button size='medium' color='brown' onClick={this.getServicesPG.bind(this, 'Meedoen')}>Meedoen</Button>
            <Button size='medium' color='green' onClick={this.getServicesPG.bind(this, 'Dagelijks Functioneren')}>Dagelijks Functioneren</Button>
        </Segment>;
    }

    // Custom rendering for the dropdown items with increased font size
    renderDropdownItem = (option) => (
        <div style={{ fontSize: '1.5em', padding: '10px' }}>
            {option.text}
        </div>
    )

    showServices = () => {
        const { t } = this.props;
        const { codableConcept } = this.state;
        return (<Fragment>
            <Modal open={this.state.isModalOpen} onClose={this.handleCloseModal} size="large" dimmer='blurring' >
                <Modal.Header>{t("Choose Support Need")}</Modal.Header>
                <Modal.Content>
                    <Segment>
                        <Dropdown
                            size='large'
                            placeholder={t("Choose Support Need")}
                            selection
                            search
                            options={this.getOptions()}
                            fluid
                            clearable
                            value={codableConcept}
                            onChange={this.handleDropdownChange}
                            style={{ fontSize: '1.5em' }} // Increase input text size
                            itemRenderer={this.renderDropdownItem} // Custom renderer for items
                        />
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="blue" onClick={this.handleCloseModal}>
                        <Icon name="close" /> {t("Show Offer")}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Segment basic style={{ backgroundColor: '#385773', height: '5px' }} />
            <Grid columns={16} inverted style={{ backgroundColor: '#385773' }}>
                <GridColumn width={4}></GridColumn>
                <GridColumn width={8}><Header size='huge' textAlign='center' inverted>{t("Services")}<Header.Subheader>Valaarwijk, Wilrijk</Header.Subheader></Header></GridColumn>
                <GridColumn width={4} verticalAlign='middle' textAlign='right'>
                    <Image src={zipsterLogo} size='small' /></GridColumn>
            </Grid>
            <Segment >
                <Menu text >
                    <Menu.Item>
                    </Menu.Item>
                    <Menu.Item>
                        {this.getButtons()}
                    </Menu.Item>
                    <Menu.Menu text position='right'>
                        <Menu.Item>
                        </Menu.Item>
                        <Menu.Item>
                            <Button size="large" color='blue' onClick={this.handleOpenModal}>
                                {t("Search By Need")}
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Divider />
                {this.getServicesMap()}
            </Segment>
        </Fragment >
        );
    }

    recaptchaSuccess = (value) => {
        this.setState({ recaptchaValue: value });
    }

    recaptchaExpired = (value) => {
        this.setState({ recaptchaValue: null });
    }

    render() {
        if (!this.state.recaptchaValue) {
            return <Segment textAlign='center' basic>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={this.recaptchaRef}
                    onChange={(this.recaptchaSuccess)}
                    onExpired={(this.recaptchaExpired)} />
            </Segment>;
        } else {
            return this.showServices();
        }
    }

}

export default withTranslation()(PublicServices);
