import React, { Component, Fragment } from 'react';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { Container, Grid, Menu, Icon, Divider } from 'semantic-ui-react';
import ProviderDashboard from '../components/ProviderDashboard';
import ServiceDashboard from './ServiceDashboard';
import Questionnaire from '../components/Questionnaire';
import Patient from '../components/Patient';
import Services from '../components/Services';
import Profile from '../components/Profile';
import Organisation from '../components/Organisation';
import ServiceRequest from '../components/ServiceRequest';
import Contact from '../components/Contact';
import Faq from '../components/Faq';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeader } from '../helpers/auth-header.js';
import Configuration from '../config/Configuration.js';
import ErrorBoundary from '../components/ErrorBoundary';
import RegisterPatientForm from './RegisterPatientForm';
import ChooseReferralType from './ChooseReferralType';
import DirectReferral from './DirectReferral';
import NeedsBasedReferral from './NeedsBasedReferral';
import ZoneDashboard from './ZoneDashboard';
import ReferralOverview from './ReferralOverview';
import UserOverview from './UserOverview';
import { withTranslation } from 'react-i18next';
import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});

const { Media, MediaContextProvider } = AppMedia;

class HealthMatchBody extends Component {



    constructor(props) {
        super(props);
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeItem: 'patients',
            activeUser: this.authenticationService.getActiveUser(),
            organizationType: '',
            serviceRequestStatus: '',
            onlyMyCases: false,
            onlyHouseVisits: false,
            onlyFirstFollowUp: false,
            onlySecondFollowUp: false,
            onlyHighRiskContacts: false,
            onlyForeignSpeakers: false,
            onlyVulnerablePeople: false,
            onlyWithPhoneNumber: false,
            onlyClosureNeeded: false,
            activePage: 1,
            postalCode: null
        }
    }

    handleError = (error) => {
        console.log(error.message);
    }

    logout = () => {
        this.props.logout();
    }

    setServiceRequestStatus = (status) => {
        this.setState({ serviceRequestStatus: status });
    }

    toggleOnlyMyCases = () => {
        this.setState({
            onlyMyCases: !this.state.onlyMyCases
        });
    }

    toggleOnlyHouseVisits = () => {
        this.setState({
            onlyHouseVisits: !this.state.onlyHouseVisits,
            onlyFirstFollowUp: false,
            onlySecondFollowUp: false,
            onlyHighRiskContacts: false,
            onlyForeignSpeakers: false,
            onlyVulnerablePeople: false
        });
    }

    toggleOnlyFirstFollowUp = () => {
        this.setState({
            onlyFirstFollowUp: !this.state.onlyFirstFollowUp,
            onlySecondFollowUp: false,
            onlyHighRiskContacts: false,
            onlyForeignSpeakers: false,
            onlyVulnerablePeople: false,
            onlyHouseVisits: false
        });
    }

    toggleOnlySecondFollowUp = () => {
        this.setState({
            onlySecondFollowUp: !this.state.onlySecondFollowUp,
            onlyHighRiskContacts: false,
            onlyForeignSpeakers: false,
            onlyVulnerablePeople: false,
            onlyHouseVisits: false,
            onlyFirstFollowUp: false
        });
    }

    toggleOnlyHighRiskContacts = () => {
        this.setState({
            onlyHighRiskContacts: !this.state.onlyHighRiskContacts,
            onlyForeignSpeakers: false,
            onlyVulnerablePeople: false,
            onlyHouseVisits: false,
            onlyFirstFollowUp: false,
            onlySecondFollowUp: false
        });
    }

    toggleOnlyForeignSpeakers = () => {
        this.setState({
            onlyForeignSpeakers: !this.state.onlyForeignSpeakers,
            onlyVulnerablePeople: false,
            onlyHouseVisits: false,
            onlyFirstFollowUp: false,
            onlyHighRiskContacts: false
        });
    }

    toggleOnlyVulnerablePeople = () => {
        this.setState({
            onlyVulnerablePeople: !this.state.onlyVulnerablePeople,
            onlyHouseVisits: false,
            onlyFirstFollowUp: false,
            onlyHighRiskContacts: false,
            onlyForeignSpeakers: false
        });
    }

    toggleOnlyWithPhoneNumber = () => {
        this.setState({
            onlyWithPhoneNumber: !this.state.onlyWithPhoneNumber
        });
    }

    toggleOnlyClosureNeeded = () => {
        this.setState({
            onlyClosureNeeded: !this.state.onlyClosureNeeded
        });
    }

    setPostalCode = (postalCode) => {
        this.setState({
            postalCode: postalCode,
        });
    }

    setActivePage = (activePage) => {
        this.setState({
            activePage: activePage,
        });
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.activeUser.organization.uuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.setState({
                    organizationType: organization.type
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getFullDashboard = () => {
        return <Fragment>
            <Divider hidden style={{ marginTop: '4em' }} />
            <Grid padded='horizontally' stackable>
                <Grid.Column width={13}>
                    <ErrorBoundary>
                        {this.getDashboardBody()}
                    </ErrorBoundary>
                </Grid.Column>
                <Grid.Column width={3}>
                    {this.getDashboardMenu()}
                </Grid.Column>
            </Grid>
        </Fragment>;
    }

    getMobileDashboard = () => {
        return <Fragment>
            <Divider hidden style={{ marginTop: '4em' }} />
            <ErrorBoundary>
                {this.getDashboardBody()}
            </ErrorBoundary>
        </Fragment>;
    }

    getDashboard = () => {
        return <MediaContextProvider>
            <Container as={Media} greaterThanOrEqual="computer" fluid>
                {this.getFullDashboard()}
            </Container>
            <Container as={Media} lessThan="computer" fluid>
                {this.getMobileDashboard()}
            </Container>
        </MediaContextProvider>
    }

    componentDidMount() {
        this.retrieveOrganization();
    }

    getReferrerMenu = () => {
        const { t } = this.props;
        const { activeItem } = this.state;
        if (this.state.activeUser) {
            if (this.state.organizationType === "Apotheek") {
                return <Fragment>
                    <Menu.Item>
                        <Menu.Header>{t("Overview")}</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item
                                as={Link}
                                to='/'
                                name='requests'
                                active={activeItem === 'requests'}
                                onClick={this.handleItemClick}
                            ><Icon name='street view' color='blue' />{t("Requests")}</Menu.Item>
                        </Menu.Menu>
                    </Menu.Item>
                </Fragment >
            } else {
                return <Fragment>
                    <Menu.Item>
                        <Menu.Header>{t("Overview")}</Menu.Header>
                        <Menu.Menu>
                            <Menu.Item
                                as={Link}
                                to='/'
                                name='patients'
                                active={activeItem === 'patients'}
                                onClick={this.handleItemClick}
                            ><Icon name='street view' color='blue' />{t("Patients")}</Menu.Item>
                            <Menu.Item
                                as={Link}
                                to='/services'
                                name='services'
                                active={activeItem === 'services'}
                                onClick={this.handleItemClick}
                            ><Icon name='handshake' color='blue' />{t("Services")}</Menu.Item></Menu.Menu>
                    </Menu.Item>
                </Fragment>;
            }
        }
    }

    getFullMenu = () => {
        const { t } = this.props;
        const { activeItem } = this.state;
        return <Fragment>
            <Menu.Item>
                <Menu.Header>{t("Overview")}</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        as={Link}
                        to='/'
                        name='requests'
                        active={activeItem === 'requests'}
                        onClick={this.handleItemClick}
                    ><Icon name='street view' color='blue' />{t("Requests")}</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to='/referrals'
                        name='referrals'
                        active={activeItem === 'referrals'}
                        onClick={this.handleItemClick}
                    ><Icon name='mail forward' color='blue' />{t("Referrals")}</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to='/services'
                        name='services'
                        active={activeItem === 'services'}
                        onClick={this.handleItemClick}
                    ><Icon name='handshake' color='blue' />{t("Services")}</Menu.Item>
                </Menu.Menu>
            </Menu.Item>
        </Fragment >
    }

    getServiceMenu = () => {
        const { t } = this.props;
        const { activeItem } = this.state;
        return <Fragment>
            <Menu.Item>
                <Menu.Header>{t("Overview")}</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        as={Link}
                        to='/'
                        name='requests'
                        active={activeItem === 'requests'}
                        onClick={this.handleItemClick}
                    ><Icon name='street view' color='blue' />{t("Referrals")}</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to='/services'
                        name='services'
                        active={activeItem === 'services'}
                        onClick={this.handleItemClick}
                    ><Icon name='handshake' color='blue' />{t("Services")}</Menu.Item>
                </Menu.Menu>
            </Menu.Item>
        </Fragment >
    }

    getCommonMenu = () => {
        const { t } = this.props;
        const { activeItem } = this.state;
        return <Fragment>
            <Menu.Item>
                <Menu.Header>{t("Settings")}</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        as={Link}
                        to='/profile'
                        name='profile'
                        active={activeItem === 'profile'}
                        onClick={this.handleItemClick}
                    ><Icon name='edit' color='blue' />{t("My Profile")}</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to='/organisation'
                        name='organisation'
                        active={activeItem === 'organisation'}
                        onClick={this.handleItemClick}
                    ><Icon name='hospital' color='blue' />{t("My Organisation")}</Menu.Item>
                </Menu.Menu>
            </Menu.Item>
            <Menu.Item
                as={Link}
                onClick={this.logout}
                name='log out'
                active={activeItem === 'log out'}
            ><Icon name='log out' color='blue' />{t("Log Out")}</Menu.Item>
        </Fragment>
    }



    getZoneMenu = () => {
        const { t } = this.props;
        const { activeItem } = this.state;
        return <Fragment>
            <Menu.Item>
                <Menu.Header>{t("Overview")}</Menu.Header>
                <Menu.Menu>
                    <Menu.Item
                        as={Link}
                        to='/'
                        name='dashboard'
                        active={activeItem === 'dashboard'}
                        onClick={this.handleItemClick}
                    ><Icon name='dashboard' color='blue' />{t("Dashboard")}</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to='/referraloverview'
                        name='referraloverview'
                        active={activeItem === 'referraloverview'}
                        onClick={this.handleItemClick}
                    ><Icon name='mail forward' color='blue' />{t("Verwijsoverzicht")}</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to='/users'
                        name='users'
                        active={activeItem === 'users'}
                        onClick={this.handleItemClick}
                    ><Icon name='users' color='blue' />{t("Gebruikers")}</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to='/services'
                        name='services'
                        active={activeItem === 'services'}
                        onClick={this.handleItemClick}
                    ><Icon name='handshake' color='blue' />{t("Services")}</Menu.Item>
                </Menu.Menu>
            </Menu.Item>
        </Fragment >
    }

    getDashboardMenu = () => {
        if (this.state.activeUser) {
            if (this.state.organizationType.includes(this.config.HYBRID_ORGANISATION)) {
                return <Container>
                    <Menu vertical fluid size='large'>
                        {this.getFullMenu()}
                        {this.getCommonMenu()}
                    </Menu>
                </Container>;
            } else {
                if (this.state.activeUser.isObserver) {
                    return <Container>
                        <Menu vertical fluid size='large'>
                            {this.getServiceMenu()}
                            {this.getCommonMenu()}
                        </Menu>
                    </Container>;
                } else {
                    if (this.state.organizationType === "ELZ") {
                        return <Container>
                            <Menu vertical fluid size='large'>
                                {this.getZoneMenu()}
                                {this.getCommonMenu()}
                            </Menu>
                        </Container>;
                    } else {
                        return <Container>
                            <Menu vertical fluid size='large'>
                                {this.getReferrerMenu()}
                                {this.getCommonMenu()}
                            </Menu>
                        </Container>;
                    }
                }
            }
        } else {
            return null;
        }
    }

    getDashboardBody = () => {
        if (this.state.activeUser) {
            if (this.state.activeUser.isObserver) {
                return <Routes>
                    <Route path='/' element={<ServiceDashboard />} />
                    <Route path='/referrals' element={<ProviderDashboard />} />
                    <Route path='/servicerequest' element={<ServiceRequest />} />
                    <Route path='/patient' element={<Patient />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/organisation' element={<Organisation />} />
                    <Route path='/services' element={<Services />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/faq' element={<Faq />} />
                    <Route path='/newcontact' element={<RegisterPatientForm />} />
                    <Route path='/choosereferral' element={<ChooseReferralType />} />
                    <Route path='/directreferral' element={<DirectReferral />} />
                    <Route path='/needsbasedreferral' element={<NeedsBasedReferral />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>;
            } else {
                if (this.state.organizationType === "ELZ") {
                    return <Routes>
                        <Route path='/' element={<ZoneDashboard />} />
                        <Route path='/referraloverview' element={<ReferralOverview />} />
                        <Route path='/users' element={<UserOverview />} />
                        <Route path='/services' element={<Services />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/organisation' element={<Organisation />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>;
                } else {
                    return <Routes>
                        <Route path='/' element={<ProviderDashboard />} />
                        <Route path='/profile' element={<Profile />} />
                        <Route path='/organisation' element={<Organisation />} />
                        <Route path='/patient' element={<Patient />} />
                        <Route path='/services' element={<Services />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/faq' element={<Faq />} />
                        <Route path='/newcontact' element={<RegisterPatientForm />} />
                        <Route path='/choosereferral' element={<ChooseReferralType />} />
                        <Route path='/directreferral' element={<DirectReferral />} />
                        <Route path='/needsbasedreferral' element={<NeedsBasedReferral />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>;
                }
            }
        } else {
            return null;
        }
    }

    render() {
        return <Routes>
            <Route path="/questionnaire" element={<Questionnaire organizationType={this.state.organizationType} />} />
            <Route path="*" element={this.getDashboard()} />
        </Routes>;
    }
}

export default withTranslation()(HealthMatchBody);
