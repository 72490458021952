import React, { Component, Fragment } from 'react';
import { Segment, Image, List, Menu, Divider, Table, Pagination } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeader, authHeaderSet } from '../helpers/auth-header.js';
import logogreen from '../images/state-green.png';
import logoorange from '../images/state-orange.png';
import logored from '../images/state-red.png';
import logogrey from '../images/state-grey.png';
import logoyellow from '../images/state-yellow.png';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

class ReferralOverview extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            startDate: new Date(new Date() - 365 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
            serviceRequests: [],
            startIndex: 0,
            endIndex: 9
        }
    }

    loadCodableConcepts() {
        let codableTexts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    let uuid = concept.uuid;
                    let text = concept.text;
                    codableTexts.push({ uuid, text });
                }
                this.setState({ codableConcepts: codableTexts });
                return concepts;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceRequests = () => {
        let query = '?';
        if (this.state.startDate) {
            query += "startDate=" + moment(this.state.startDate).toJSON() + "&endDate=" + moment(this.state.endDate).toJSON();
        }
        query += "&groupByHealthCareService=true&groupByCodableConcept=true&groupByDay=true&groupByType=true&groupByStatus=true&groupByOrganization=true";
        return fetch(this.config.SERVICEREQUESTS_ANALYTICS + query, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(requests => {
                requests.sort((a, b) => {
                    return new Date(b.day) - new Date(a.day);
                })
                this.setState({
                    serviceRequests: requests,
                    totalElements: requests.length,
                    totalPages: Math.ceil(requests.length / 10)
                })
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    componentDidMount() {
        this.loadCodableConcepts();
        this.retrieveServiceRequests();
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    getStatus = (serviceRequest) => {
        const { t } = this.props;
        let text = '';
        switch (serviceRequest.status) {
            case "SendPatientMessageFailed":
                text = t("SD Awaiting");
                break;
            case "PatientMessageSent":
                text = t("SD Awaiting");
                break;
            case this.config.SERVICE_REQUEST_STATUS_PLANNED:
                text = t("SD Planned");
                break;
            case this.config.SERVICE_REQUEST_STATUS_WAITING_LIST:
                text = t("SD Waiting List");
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT:
                text = t("SD Support");
                break;
            case this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED:
                text = t("SD Already Supported");
                break;
            case this.config.SERVICE_REQUEST_STATUS_FORWARD:
                text = t("SD Referred");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT:
                text = t("SD Not Supported");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SHOW:
                text = t("SD No Show");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED:
                text = t("SD No Support Wanted");
                break;
            case this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE:
                text = t("SD Unreachable");
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED:
                text = t("SD Support Ended");
                break;
            default:
                text = t("SD Awaiting");
        }
        return text;
    }

    getDate = (date) => {
        return new Intl.DateTimeFormat('en-GB', { month: '2-digit', day: '2-digit' }).format(Date.parse(date));
    }

    getCodableConceptText = (serviceRequest) => {
        const { t } = this.props;
        let codableConceptTexts = [];
        let codableConcepts = serviceRequest.codableConcepts;
        if (codableConcepts) {
            for (var j = 0; j < codableConcepts.length; j++) {
                let codableConcept = codableConcepts[j];
                for (var i = 0; i < this.state.codableConcepts.length; i++) {
                    if (this.state.codableConcepts[i].uuid === codableConcept.uuid) {
                        codableConceptTexts.push(this.state.codableConcepts[i].text);
                    }
                }
            }
            if (codableConceptTexts.length > 0) {
                return <List>
                    {codableConceptTexts.slice(0, codableConceptTexts.length).map((codableConceptText, index) => {
                        return <List.Item key={index}><List.Icon name='angle double right' color='blue' />{t(codableConceptText)}</List.Item>
                    })}
                </List>
            }
        } else {
            return null;
        }
    }

    getCodableConcept = (uuid) => {
        for (var i = 0; i < this.state.codableConcepts.length; i++) {
            if (this.state.codableConcepts[i].uuid === uuid) {
                return this.state.codableConcepts[i].text;
            }
        }
    }

    getType = (type) => {
        if (type === "consult") {
            return "Vragenlijst";
        } else if (type === "consult-questionnaire") {
            return "Vragenlijst";
        } else if (type === "consult-direct-referral") {
            return "Directe Doorverwijzing";
        } else if (type === "consult-needs-based") {
            return "Vanuit De Noden";
        }
    }

    getLogo = (serviceRequest) => {
        let src = logoorange;
        switch (serviceRequest.status) {
            case "ConsentCreated":
                src = logoorange;
                break;
            case "PatientMessageSent":
                src = logoorange;
                break;
            case "SendPatientMessageFailed":
                src = logoorange;
                break;
            case "SendMessageFailed":
                src = logoorange;
                break;
            case this.config.SERVICE_REQUEST_STATUS_PLANNED:
                src = logoyellow;
                break;
            case this.config.SERVICE_REQUEST_STATUS_WAITING_LIST:
                src = logoorange;
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_SUPPORT_ENDED:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_ALREADY_SUPPORTED:
                src = logogreen;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NOT_REACHABLE:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SHOW:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_NO_SUPPORT_WANTED:
                src = logored;
                break;
            case this.config.SERVICE_REQUEST_STATUS_FORWARD:
                src = logogrey;
                break;
            default:
                src = logoorange;
                break;
        }
        return <Image
            circular
            floated='left'
            size='mini'
            src={src}
        />
    }

    handlePaginationChange = (e, { activePage }) => this.setState({
        startIndex: (activePage - 1) * 8,
        endIndex: (activePage) * 8
    })

    getServiceRequestsTable = () => {
        const { t } = this.props;
        const {
            activePage,
            totalPages,
        } = this.state;
        return <Fragment>
            <Table selectable striped unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>{t("Verwijzer")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Ondersteuner")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Support Needs")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Type Verwijzing")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.state.serviceRequests.slice(this.state.startIndex, this.state.endIndex).map((serviceRequest, index) => {
                        return <Table.Row>
                            <Table.Cell collapsing textAlign='center'>{this.getLogo(serviceRequest)}</Table.Cell>
                            <Table.Cell>{serviceRequest.organization.name}</Table.Cell>
                            <Table.Cell>{serviceRequest.healthcareService.name}</Table.Cell>
                            <Table.Cell>{this.getCodableConcept(serviceRequest.codableConcept.uuid)}</Table.Cell>
                            <Table.Cell>{this.getStatus(serviceRequest)}</Table.Cell>
                            <Table.Cell>{this.getType(serviceRequest.type)}</Table.Cell>
                            <Table.Cell>{serviceRequest.day}</Table.Cell>
                        </Table.Row>
                    })
                    }
                </Table.Body>
            </Table>
            <Segment basic textAlign='center'>
                <Pagination
                    activePage={activePage}
                    onPageChange={this.handlePaginationChange}
                    size='mini'
                    totalPages={totalPages} />
            </Segment>
        </Fragment>;
    }

    getServiceRequests = () => {
        return this.getServiceRequestsTable();
    }

    render() {
        const { t } = this.props;
        return <Segment>
            <Menu text >
                <Menu.Menu text='true' position='right'>
                    <Menu.Item>
                        {this.state.totalElements} {t("Results")}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Divider />
            {this.getServiceRequests()}
        </Segment>;
    }

}

function WithNavigateWrapper(props) {
    const navigate = useNavigate();
    return <ReferralOverview {...props} navigate={navigate} />;
}

export default withTranslation()(WithNavigateWrapper);
