import React, { Component, Fragment } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { Container, Divider, Grid, Header, Image, List, Segment, Modal, Button, Icon, Form } from 'semantic-ui-react';
import IdleTimer from 'react-idle-timer';
import Configuration from './config/Configuration.js';
import AuthenticationService from './api/AuthenticationService.js';
import HealthMatchBody from './components/HealthMatchBody';
import ZipsterMenu from './components/ZipsterMenu';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import RegistrationForm from './components/RegistrationForm';
import { withTranslation } from 'react-i18next';
import detectBrowserLanguage from 'detect-browser-language';
import { createMedia } from "@artsy/fresnel";
import { authHeader } from './helpers/auth-header.js';

import './App.css';
import logo from './images/logo-colour-small.png';
import i18n from "./i18n";
import PublicServices from './components/PublicServices.jsx';


const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920
  }
});

const { Media, MediaContextProvider } = AppMedia;

class App extends Component {

  constructor(props) {
    super(props);
    this.authenticationService = new AuthenticationService();
    this.idleTimer = null;
    this.config = new Configuration();
    this.state = {
      timeout: 1200000,
      isIdle: false,
      showLogoutModal: false,
      activeUser: null,
      showFeedbackModal: false,
      feedbackText: '',
    }
    // Bind event handlers and methods
    this.onIdle = this.onIdle.bind(this)
  }

  createFeedback(notes) {
    const currentUrl = window.location.href;
    return fetch(this.config.FEEDBACK_URL, {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        authored: new Date().toISOString(),
        notes: notes,
        practitionerRole: this.authenticationService.getActiveUser().uuid,
        location: currentUrl
      })
    }).then(response => {
      if (!response.ok) {
        let errorResponse = this.handleResponseError(response);
        return errorResponse;
      }
      return response.json();
    }).catch(error => {
      this.handleError(error);
    });
  }

  detectLanguage = () => {
    var languageDetected = false;
    if (!languageDetected) {
      var language = detectBrowserLanguage();
      if (language !== null) {
        if (language.startsWith("nl")) {
          this.setDutch();
        } else if (language.startsWith("fr")) {
          this.setFrench();
        } else {
          this.setDutch();
        }
      }
    }
  }

  getLanguage = () => {
    var language = detectBrowserLanguage();
    if (language !== null) {
      if (i18n.language.startsWith("nl")) {
        return "Français";
      } else if (i18n.language.startsWith("fr")) {
        return "Nederlands";
      } else {
        return "Français";
      }
    }
  }

  changeLanguage = () => {
    var language = detectBrowserLanguage();
    if (language !== null) {
      if (i18n.language.startsWith("nl")) {
        this.setFrench();
      } else if (i18n.language.startsWith("fr")) {
        this.setDutch();
      } else {
        return "Français";
      }
    }
  }

  setFrench = () => {
    i18n.changeLanguage('fr');
  }

  setDutch = () => {
    i18n.changeLanguage('nl');
  }

  componentDidMount() {
    this.detectLanguage();
  }

  onIdle() {
    if (!this.authenticationService.keepUserLoggedIn()) {
      this.setState({ isIdle: true, showLogoutModal: true, activeUser: null })
    }
  }

  logout = () => {
    this.setState({ showLogoutModal: false })
    this.authenticationService.logout();
    this.props.navigate("/login");
    this.setState({ isIdle: false, activeUser: null })
  }

  authenticated = () => {
    let activeUser = this.authenticationService.getActiveUser();
    if (activeUser) {
      this.setState({ activeUser: activeUser })
    }
  }

  getLoginPage = () => {
    return <LoginForm authenticated={this.authenticated} />;
  }

  getSignupPage = () => {
    return <SignupForm />;
  }

  getRegistrationPage = () => {
    return <RegistrationForm />;
  }

  getPublicServicesPage = () => {
    return <PublicServices />;
  }

  getBody = () => {
    return <HealthMatchBody logout={this.logout} />;
  }

  getTermsOfUse = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/conditions-dutilisation/';
    } else {
      return 'https://www.zipster.care/gebruikersvoorwaarden/';
    }
  }

  getPrivacyPolicy = () => {
    if (i18n.language.startsWith("fr")) {
      return 'https://www.zipster.care/politique-de-confidentialite/';
    } else {
      return 'https://www.zipster.care/privacybeleid/';
    }
  }

  getFooter = () => {
    const { t } = this.props;
    return <MediaContextProvider>
      <Segment color='blue' inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
        <Container textAlign='center'>
          <Container as={Media} greaterThanOrEqual="computer">
            <Grid divided inverted stackable>
              <Grid.Column width={5}>
                <Header inverted as='h4' content={t("My Profile")} />
                <List link inverted>
                  <List.Item as={Link} to='/profile'>{t("My Profile")}</List.Item>
                  <List.Item as={Link} to='/organisation'>{t("My Organisation")}</List.Item>
                  <List.Item as={Link} onClick={this.changeLanguage}>{this.getLanguage()}</List.Item>
                  <List.Item onClick={this.logout} as='a'>{t("Log Out")}</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header inverted as='h4' content={t("About Us")} />
                <List link inverted>
                  <List.Item as={Link} to='/contact'>{t("Contact Us Main")}</List.Item>
                  <List.Item as={Link} to='/faq'>{t("FAQ")}</List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header inverted as='h4' style={{ fontFamily: 'Montserrat' }} content='zipster.care' />
                <p>
                  {t("Tagline")}
                </p>
              </Grid.Column>
            </Grid>
            <Divider inverted section />
          </Container>
          <Image centered size='tiny' src={logo} />
          <Container as={Media} greaterThanOrEqual="computer">
            <List horizontal inverted divided link size='small'>
              <List.Item as={Link} to='/contact'>
                {t("Contact Us Main")}
              </List.Item>
              <List.Item as='a' href={this.getTermsOfUse()} target='_blank'>
                {t("Terms Of Use")}
              </List.Item>
              <List.Item as='a' href={this.getPrivacyPolicy()} target='_blank'>
                {t("Privacy Policy")}
              </List.Item>
            </List>
          </Container>
          <Container as={Media} lessThan="computer">
            <Divider hidden />
            <List inverted link relaxed size='small' >
              <List.Item onClick={this.logout} as='a'>{t("Log Out")}</List.Item>
            </List>
          </Container>
        </Container>
      </Segment>
    </MediaContextProvider>;
  }

  handleOpenFeedbackModal = () => {
    this.setState({ showFeedbackModal: true });
  };

  handleCloseFeedbackModal = () => {
    this.setState({ showFeedbackModal: false });
  };

  handleFeedbackChange = (e, { value }) => {
    this.setState({ feedbackText: value });
  };

  handleSubmitFeedback = () => {
    // Call the createFeedback method with the feedback text
    this.createFeedback(this.state.feedbackText)
      .then(() => {
        // Optionally show a success message or clear the feedback form
        this.setState({ feedbackText: '', showFeedbackModal: false });
      })
      .catch((error) => {
        // Handle any errors that occur during feedback submission
        console.error("Error submitting feedback:", error);
      });
  }
  

  getFeedbackModal = () => {
    const { t } = this.props;
    if (!this.state.showFeedbackModal) return null;
    return (
      <Modal open={this.state.showFeedbackModal} onClose={this.handleCloseFeedbackModal}>
        <Modal.Header>{t("Your Feedback Matters")}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.TextArea
              label={t("Your Experience")}
              placeholder={t("Your Experience Placeholder")}
              value={this.state.feedbackText}
              onChange={this.handleFeedbackChange}
              rows={10}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={this.handleCloseFeedbackModal}>
            {t("Cancel")}
          </Button>
          <Button icon='checkmark' labelPosition='right' content={t("Send")} onClick={this.handleSubmitFeedback} />
        </Modal.Actions>
      </Modal>
    );
  }

  getHomePage = () => {
    return <div>
      <ZipsterMenu />
      {this.getBody()}
      {this.getFooter()}
      <MediaContextProvider>
        <Media at='mobile'>
          {this.getFeedbackModal()}
          <Button
            icon='comment'
            color='blue'
            circular
            size='large'
            style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
            onClick={this.handleOpenFeedbackModal}
          />
        </Media>
      </MediaContextProvider>

    </div>;
  }

  render() {
    console.log('Rendering app.js');
    const { t } = this.props;
    let activeUser = this.authenticationService.getActiveUser()
    if (this.state.activeUser || activeUser) {
      return <Fragment>
        <Modal
          open={this.state.showLogoutModal}
          onClose={this.logout}
          basic
          size='small'
          dimmer='blurring'>
          <Header icon='lock' content={t("Session Expired")} />
          <Modal.Content>
            <h3>{t("Login Again")}</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color='blue' onClick={this.logout} inverted>
              <Icon name='checkmark' />OK
            </Button>
          </Modal.Actions>
        </Modal>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.onIdle}
          timeout={this.state.timeout}
          startOnLoad />
        <Routes>
          <Route path='*' element={this.getHomePage()} />
        </Routes>
      </Fragment>;
    } else {
      return <Routes>
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/publicservices" element={<PublicServices />} />
        <Route path='*' element={this.getLoginPage()} />
      </Routes>;
    }
  }

}

function WithNavigateWrapper(props) {
  const navigate = useNavigate();
  return <App {...props} navigate={navigate} />;
}

export default withTranslation()(WithNavigateWrapper);